import React from "react";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import "./about.scss";

const data = [
  {
    title: "Overview",
    content: () => (
      <>
        <p className="content-paragraph">
          BaseOne empowers you to create unlimited virtual accounts for your
          users and customers. Leverage the power of BaseOne to start creating
          virtual account numbers on a provider bank of your choice. Get started
          by choosing a provider bank.
        </p>
        <p>
          BaseOne enables you to create any type of virtual account you need to
          power your business. See the various types of virtual account you can
          create below.
        </p>
      </>
    ),
  },
  {
    title: "Dynamic",
    content: () => (
      <p className="content-paragraph">
        Dynamic virtual accounts enables you to create virtual accounts that are
        only valid for a limited time - typically 30 minutes.
      </p>
    ),
  },
  {
    title: "Static-collection",
    content: () => (
      <p className="content-paragraph">
        Static collection virtual account types generates permanent virtual
        account numbers. Unlike dynamic types, this won't expire.
      </p>
    ),
  },
  {
    title: "Static",
    content: () => (
      <p className="content-paragraph">
        BaseOne static virtual account is a powerful system that enables
        businesses create permanent virtual account numbers that are mapped to
        BaseOne's ledger.
      </p>
    ),
  },
  {
    title: "Tutorials",
    content: () => ``,
  },
  {
    title: "Documentation",
    content: () => (
      <ul>
        <li className="mb-3">
          <a
            href="https://developer.baseone.co/docs/virtualaccounts/"
            target="_blank"
            rel="noreferrer"
          >
            Click here to learn more <i className="ri-external-link-line"></i>
          </a>
        </li>
        <li>
          <a
            href="https://developer.baseone.co/docs/authentication/"
            target="_blank"
            rel="noreferrer"
          >
            How to get your API Keys <i className="ri-external-link-line"></i>
          </a>
        </li>
      </ul>
    ),
  },
];

const About = () => {
  return (
    <div className="about-page row">
      <div className="col-7">
        {data.map((v) => (
          <div key={v.title}>
            <div className="sp-mt--24 sp-mb--8">
              <Typography
                typographyVariant="section-title"
                text={v.title}
                className="font-weight-bold"
              />
            </div>
            <div>{v.content()}</div>
          </div>
        ))}
      </div>
      <div className="col-2"></div>
      <div className="col-3">
        <div className="sp-mt--24 sp-mb--16">
          <Typography
            typographyVariant="subtitle1"
            text="Additional Information"
            className="font-weight-bold"
          />
        </div>
        <div className="">
          <p className="side-paragraph">
            <span className="labels">Runs on</span> :{" "}
            <span className="values">Kernel Cloud Server</span>
          </p>
          <p className="side-paragraph">
            <span>Type</span> :{" "}
            <span
              className="values primary linked"
              onClick={() =>
                window.open(
                  "https://developer.baseone.co/docs/authentication/",
                  "_blank"
                )
              }
            >
              Saas & APIs
            </span>
          </p>
          <p className="side-paragraph">
            <span className="labels">Last updated</span> :{" "}
            <span className="values">20/02/2021</span>
          </p>
          <p className="side-paragraph">
            <span className="labels">Category</span> :{" "}
            <span className="values primary">
              <span
                className="linked"
                onClick={() =>
                  window.open(
                    "https://developer.baseone.co/docs/authentication/",
                    "_blank"
                  )
                }
              >
                Analytics
              </span>
              ,{" "}
              <span
                className="linked"
                onClick={() =>
                  window.open(
                    "https://developer.baseone.co/docs/authentication/",
                    "_blank"
                  )
                }
              >
                Finance
              </span>{" "}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
