import { Post, Get, All, Put } from "../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { serviceManagerAdminBaseUrl, serviceManagerBaseUrl, virtualAccountBaseUrl, virtualAccountBaseUrlWithoutApi } from "apiServices/config.service";

export const Type = {
  ONBOARDING: "ONBOARDING",
  ONBOARD_MULTIPLE: "ONBOARD_MULTIPLE",
  PROVIDER_LIST: "PROVIDER_LIST",
  VIRTUAL_ACCOUNT: "VIRTUAL_ACCOUNT",
  COUNTRIES: "COUNTRIES",
  ALL_PROVIDERS: "ALL_PROVIDERS",
  CHECK_MERCHANT_STATUS: "CHECK_MERCHANT_STATUS",
  REPORTS: "REPORTS",
  GET_ACCOUNTS: "GET_ACCOUNTS",
  TRANSACTIONS: "TRANSACTIONS",
  GET_TRANSFERS: "GET_TRANSFERS",
  GET_BANKS: "GET_BANKS",
  GET_STATIC_ACCOUNTS: "GET_STATIC_ACCOUNTS",
  FETCH_COLLECTION_WALLETS: "FETCH_COLLECTION_WALLETS",
  INTRA_TRANSFER: "INTRA_TRANSFER",
  INTER_TRANSFER: "INTER_TRANSFER",
  UPDATE_SETTINGS: "UPDATE_SETTINGS",
  FETCH_MERCHANT: "FETCH_MERCHANT",
  RESOLVE_BANK_ACCOUNT: "RESOLVE_BANK_ACCOUNT",
  FETCH_SUPPORTED_BANKS: "FETCH_SUPPORTED_BANKS",
  FETCH_CHARGEBACK_DATA: "FETCH_CHARGEBACK_DATA",
  FETCH_SETTLEMENTS: "FETCH_SETTLEMENTS",
  FETCH_SETTLEMENT_TRANSACTIONS: "FETCH_SETTLEMENT_TRANSACTIONS",
  WEBHOOK_NOTIFICATION: "WEBHOOK_NOTIFICATION",
  GET_ACCOUNT_DETAIL: "GET_ACCOUNT_DETAIL",
  NEW_ACCOUNT: "NEW_ACCOUNT",
  PROVIDER_COUNTRY: "PROVIDER_COUNTRY",
  PROVIDERS: "PROVIDERS",
  ACCOUNT_TYPES: "ACCOUNT_TYPES",
};

export const onboardProvider = createAsyncAction(
  Type.ONBOARDING,
  async (data) => {
    return await Post("v1/merchant", data);
  }
);

// export const fetchTransactions = createAsyncAction(
//   Type.TRANSACTIONS,
//   async (data) => {
//     return await Post("v1/transactions", data);
//   }
// );

export const getTransfers = createAsyncAction(
  Type.GET_TRANSFERS,
  async (pageNumber, size, keyword) => {
    return await Get(
      `v1/Transaction/transfers?pageNumber=${pageNumber || 1}&pageSize=${
        size || 10
      }&keyword=${keyword || ""}`
    );
  }
);

// export const onboardMultipleProvider = createAsyncAction(
//   Type.ONBOARD_MULTIPLE,
//   async (data) => {
//     return await All("v1/merchant", data);
//   }
// );

// export const getProviderList = createAsyncAction(
//   Type.PROVIDER_LIST,
//   async (merchantId, pageNumber = 1, size = 10) => {
//     return await Get(
//       `v1/Provider/merchantprovider/${merchantId}?pageSize=${size}&pageNumber=${pageNumber}&IsAscending=false`
//     );
//   }
// );

// export const getReports = createAsyncAction(Type.REPORTS, async () => {
//   return await Get(`v1/VirtualAccount/report`);
// });

export const fetchTransactions = createAsyncAction(
  Type.TRANSACTIONS,
  async ({ pageNumber, pageSize, search }) => {
    return await Get(
      `v1/Transaction/getallcredittransactions?PageIndex=${pageNumber}&PageSize=${pageSize}${
        search ? "&Keyword=" + search : ""
      }`
    );
  }
);

export const fetchChargebackData = createAsyncAction(
  Type.FETCH_CHARGEBACK_DATA,
  async ({ pageNumber, pageSize, search, status }) => {
    return await Get(
      `v1/Chargeback/chargeback/getall?PageIndex=${pageNumber}&PageSize=${pageSize}${
        search ? "&Keyword=" + search : ""
      }${status ? "&status=" + status : ""}`
    );
  }
);

export const onboardMultipleProvider = createAsyncAction(
  Type.ONBOARD_MULTIPLE,
  async (data) => {
    return await All("v1/Merchant", data);
  }
);

export const getProviderList = createAsyncAction(
  Type.PROVIDER_LIST,
  async (merchantId, pageNumber = 1, size = 10) => {
    return await Get(
      `v1/Provider/merchantprovider/${merchantId}?pageSize=${size}&pageNumber=${pageNumber}&IsAscending=false`
    );
  }
);

export const fetchSupportedBanks = createAsyncAction(
  Type.FETCH_SUPPORTED_BANKS,
  async ({ countryCode }) => {
    return await Get(`v1/transfer/institutions/${countryCode}`);
  }
);

export const getBanks = createAsyncAction(Type.GET_BANKS, async () => {
  return await Get(`v1/Transfer/Institutions/NGA`);
});

export const getStaticAccounts = createAsyncAction(
  Type.GET_STATIC_ACCOUNTS,
  async (pageNumber, size, isAscending) => {
    return await Get(
      `v1/VirtualAccount/getstaticaccounts?pageNumber=${
        pageNumber || 1
      }&pageSize=${size || 10}&isAscending=${isAscending || false}`
    );
  }
);

export const fetchCollectionWallets = createAsyncAction(
  Type.FETCH_COLLECTION_WALLETS,
  async (pageNumber, size, isAscending) => {
    return await Get(
      `v1/VirtualAccount/getcollectionaccounts?pageNumber=${
        pageNumber || 1
      }&pageSize=${size || 10}&isAscending=${isAscending || false}`
    );
  }
);

export const intraTransfer = createAsyncAction(
  Type.INTRA_TRANSFER,
  async (payload) => {
    return await Post("v1/Transfer/Intra", payload);
  }
);

export const interTransfer = createAsyncAction(
  Type.INTER_TRANSFER,
  async (payload) => {
    return await Post("v1/VirtualAccount/account-transfer", payload);
  }
);
export const getReports = createAsyncAction(
  Type.REPORTS,
  async ({ currency, startDate, endDate }) => {
    return await Get(
      `v1/VirtualAccount/report?Currency=${currency}${
        startDate ? "&StartDate=" + startDate : ""
      }${endDate ? "&EndDate=" + endDate : ""}`
    );
  }
);

export const checkMerchantStatus = createAsyncAction(
  Type.CHECK_MERCHANT_STATUS,
  async () => {
    return await Get("v1/Merchant/isOnboarded");
  }
);

export const fetchAllProviders = createAsyncAction(
  Type.ALL_PROVIDERS,
  async (merchantId, pageNumber = 1, size = 256) => {
    return await Get(
      `v1/Provider/merchantprovider/${merchantId}?pageSize=${size}&pageNumber=${pageNumber}&IsAscending=false`
    );
    // }
  }
);

export const getVirtualAccount = createAsyncAction(
  Type.VIRTUAL_ACCOUNT,
  async (providerId, pageNumber = 1, size = 10) => {
    return await Get(
      `v1/VirtualAccount/${providerId}?pageNumber=${pageNumber}&pageSize=${size}`
    );
  }
);

export const getAccounts = createAsyncAction(
  Type.GET_ACCOUNTS,
  async (pageNumber, size, keyword) => {
    return await Get(
      `v1/VirtualAccount/getall?pageNumber=${pageNumber || 1}&pageSize=${
        size || 10
      }${keyword ? "&keyword=" + keyword : ""}`
    );
  }
);

export const getAccountDetails = createAsyncAction(
  Type.GET_ACCOUNT_DETAIL,
  async (id) => {
    return await Get(
      `v1/account/account-details/${id}`, virtualAccountBaseUrlWithoutApi
    );
  }
);

export const newAccount = createAsyncAction(
  Type.NEW_ACCOUNT,
  async (data) => {
    return await Post(
      `v1/VirtualAccount/create`, data
    );
  }
);
export const providerCountry = createAsyncAction(
  Type.PROVIDER_COUNTRY,
  async (id) => {
    return await Get(
      `v1/Country/GetAll?pageSize=1000000&pageNumber=1&isAscending=false`, serviceManagerBaseUrl
    );
  }
);
export const accountTypes = createAsyncAction(
  Type.ACCOUNT_TYPES,
  async (id) => {
    return await Get(
      `v1/Provider/getAccountTypes`
    );
  }
);
export const getProviders = createAsyncAction(
  Type.PROVIDERS,
  async (code) => {
    return await Get(
      `v1/Provider/GetAll?pageSize=100000&pageNumber=1&keyword=${code}&IsAscending=false&status=true`
    );
  }
);

export const fetchCountries = createAsyncAction(
  Type.COUNTRIES,
  async (pageNumber = 1, size = 100) => {
    return await Get(
      `v1/Country/GetAll?pageNumber=${pageNumber}&pageSize=${size}`,
      serviceManagerBaseUrl
    );
  }
);

export const fetchMerchant = createAsyncAction(
  Type.FETCH_MERCHANT,
  async () => {
    return await Get(`v1/Merchant`);
  }
);

export const fetchSettlements = createAsyncAction(
  Type.FETCH_SETTLEMENTS,
  async (pageNumber, keyword) => {
    return await Get(
      `v1/VirtualAccount/getbatchtransactions?pageNumber=${pageNumber}&pageSize=10${
        keyword ? "&keyword=" + keyword : ""
      }`
    );
  }
);

export const fetchSettlementTransactions = createAsyncAction(
  Type.FETCH_SETTLEMENT_TRANSACTIONS,
  async ({
    batchId,
    pageNumber,
    keyword,
    startDate,
    endDate,
    settlementType,
  }) => {
    return await Get(
      `v1/VirtualAccount/getsettledtransactions?BatchId=${batchId}${
        startDate ? "&StartDate=" + startDate : ""
      }${
        endDate ? "&EndDate=" + endDate : ""
      }&pageNumber=${pageNumber}&pageSize=10${
        keyword ? "&keyword=" + keyword : ""
      }${settlementType ? "&SettlementType=" + settlementType : ""}`
    );
  }
);

export const updateSettings = createAsyncAction(
  Type.UPDATE_SETTINGS,
  async ({ payload }) => {
    return await Put(`v1/Merchant`, payload);
  }
);

export const resolveBankAccount = createAsyncAction(
  Type.RESOLVE_BANK_ACCOUNT,
  async (accountNumber, institutionCode, countryCode) => {
    return await Get(
      `v1/transfer/resolve-bank-account?AccountNumber=${
        accountNumber || ""
      }&institutionCode=${institutionCode || ""}&countryCode=${
        countryCode || "NGA"
      }`
    );
  }
);

export const webhookNotification = createAsyncAction(
  Type.WEBHOOK_NOTIFICATION,
  async (reference) => {
    return await Post(
      `v1/VirtualAccount/notification`, {"transactionReference": [reference]}, virtualAccountBaseUrl);
  }
);
