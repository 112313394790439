import React from "react";
import { Modal, ModalBody } from "reactstrap";
import Button from "vgg-ui-react/dist/components/Core/Button";
import checkMark from "../../assets/images/verified.gif";
import alertIcon from "../../assets/images/alert.gif";
import "./successModal.scss";

const SuccessModal = ({
  statusText,
  children,
  open,
  onClose,
  failed,
  awaitingPage,
}) => {
  return (
    <Modal
      isOpen={open}
      className={`convert-form-container ${awaitingPage ? "awaiting" : ""}`}
      centered
      backdrop="static"
    >
      <ModalBody>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="mb-3">
            <img
              src={!failed ? checkMark : alertIcon}
              alt=""
              height="100"
              width={failed ? "130" : "100"}
            />
          </div>
          <div className="mb-3">
            <h3 className="success-title">
              {failed
                ? "Result"
                : "You'll receive a status update notification via email within 2 business days."}
            </h3>
          </div>
          {statusText?.description && (
            <p className="mb-3 success-content">{statusText?.description}</p>
          )}

          {children && <div className="mb-3 w-75">{children}</div>}

          <Button
            label={failed ? "Close" : "Done"}
            background="blue"
            className="w-75"
            onClick={onClose}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SuccessModal;
