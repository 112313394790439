import React from "react";
import { Route, Switch } from "react-router";
import URLS from "helpers/urls";
import Notification from "shared/notifications/notifications";
import "remixicon/fonts/remixicon.css";
import Manager from "pages/ManagerPage/ManagerPage";
const App = () => {
  return (
    <div className={`container-fluid mb-5`} style={{ minHeight: "50vh" }}>
      <Notification />
      <Switch>
        <Route path={URLS.Home} component={Manager} />
      </Switch>
    </div>
  );
};

export default App;
