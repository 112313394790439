import { setUserDetails } from "apiServices/user.service";
import CustomSpinner from "components/CustomSpinner/CustomSpinner";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";

import { store, persistor } from "redux/store";
import App from "./App";
import "./index.scss";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://e3fba596a3a840bc95937edfdcbbc520@o4504252533964800.ingest.sentry.io/4504252538355712",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

window.renderVirtualAccountServiceApp = ({ containerId, history, userDetail, url }) => {
  setUserDetails({ ...userDetail });
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<CustomSpinner height="75vh" />}>
          <Router>
            <App url={url} />
          </Router>
        </Suspense>
      </PersistGate>
    </Provider>,
    document.getElementById(containerId)
  );
};

// unmount micro frontend function
window.unmountVirtualAccountServiceApp = ({ containerId }) => {
  if (document.getElementById(containerId)) {
    ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
  }
};

// Mount to root if it is not a micro frontend
if (!document.getElementById("VirtualAccountServiceApp-container")) {
  if (document.getElementById("root")) {
    ReactDOM.render(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<CustomSpinner height="75vh" />}>
            <Router>
              <App baseRoute="/" />
            </Router>
          </Suspense>
        </PersistGate>
      </Provider>,
      document.getElementById("root")
    );
  }
}
