import React from "react";

const CountriesHeader = () => {
  return (
    <>
      <h1
        style={{
          fontSize: 20,
          fontWeight: 600,
        }}
      >
        Onboard Provider
      </h1>
      <h2
        style={{
          fontWeight: 400,
          fontSize: 12,
          lineHeight: "16px",
          letterSpacing: "0.4px",
          color: "#3A434B",
        }}
      >
        Onboard on any provider bank of your choice to start
      </h2>
    </>
  );
};

export default CountriesHeader;
