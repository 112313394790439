import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import {
  checkMerchantStatus,
  fetchAllProviders,
  onboardProvider,
} from "redux/actions/account.action";
import { errorMessage } from "redux/actions/notification";
import { getUserDetails, signout } from "apiServices/user.service";
import swal from "@sweetalert/with-react";
import { Spinner } from "reactstrap";
import Button from "vgg-ui-react/dist/components/Core/Button";
import SelectCountry from "./SelectCountry/SelectCountry";
import SelectProviders from "./SelectProviders/SelectProviders";
import CountriesHeader from "./ModalHeaders/ContriesHeader";
import ProvidersHeader from "./ModalHeaders/ProvidersHeader";
import "./providerModal.scss";

const mockCountries = [
  { id: 1, name: "Nigeria", code: "NGN", active: true },
  { id: 2, name: "Ghana", code: "GHA", active: false },
  { id: 3, name: "Kenya", code: "KEN", active: false },
  { id: 4, name: "South Africa", code: "SA", active: false },
];

const OnboardProvidersModal = ({ open, toggle, onSuccess, onClosed }) => {
  const [state, setState] = useState({
    List: [],
    MasterChecked: false,
    SelectedList: [],
    countryOptions: mockCountries,
  });
  let [submitStatus, setSubmitStatus] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [searchCountryValue, setSearchCountryValue] = useState("");
  const [searchProviderValue, setSearchProviderValue] = useState("");
  const { Client_Identifier, UserData } = getUserDetails()?.accessToken
    ? jwt_decode(getUserDetails()?.accessToken)
    : {};

  const userInfo = UserData ? JSON.parse(UserData) : null;
  const {
    loadingAllProviders,
    allProviders,
    allProvidersError,
    onboardingLoading,
    onboardingPayload,
  } = useSelector(({ account }) => account);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Client_Identifier) {
      dispatch(fetchAllProviders(Client_Identifier));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Client_Identifier]);

  useEffect(() => {
    if (!onboardingLoading && onboardingPayload && submitStatus) {
      setSubmitStatus(false);
      const successPayload = onboardingPayload?.data?.requestSuccessful
        ? [onboardingPayload?.data]
        : [];
      const failedPayload = !onboardingPayload?.data?.requestSuccessful
        ? [onboardingPayload?.data]
        : [];

      if (onSuccess) {
        onSuccess({
          failedChildren: renderAlertContent(failedPayload),
          successChildren: renderAlertContent(successPayload),
          failed: failedPayload?.length > 0,
          succeed: successPayload?.length > 0,
        });
        onClosed();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingLoading, onboardingPayload]);

  useEffect(() => {
    if (
      !loadingAllProviders &&
      !allProviders?.data?.requestSuccessful &&
      (allProviders?.data?.message || allProvidersError?.data?.message)
    ) {
      dispatch(
        errorMessage(
          allProviders?.data?.message ||
          allProvidersError?.data?.message ||
          "Error occured while processing this request"
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAllProviders]);

  useEffect(() => {
    if (allProviders?.data?.responseData?.items && open) {
      setState((prev) => ({
        ...prev,
        List: allProviders?.data?.responseData?.items.map((v) => ({
          ...v,
          selected: v.isMerchantOnboarded,
        })),
        SelectedList: allProviders?.data?.responseData?.items
          .filter((e) => e.isMerchantOnboarded)
          .map((v) => ({
            ...v,
            selected: v.isMerchantOnboarded,
          })),
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProviders, open]);

  const handleOnboardMultiple = (data) => {
    setSubmitStatus(true);
    if (state.selectedProvider) {
      if (userInfo) {
        const { id } = state.selectedProvider;
        const payload = {
          providerId: id,
          merchantName: `${userInfo.FirstName} ${userInfo.LastName}`,
          settlementType: 3,
        };
        dispatch(onboardProvider(payload));
      } else {
        swal({
          text: "You don't seem to be logged in. Kindly click okay to return to the login page.",
          icon: "info",
          button: "Okay",
        }).then(() => {
          signout();
          window.location.replace("/");
        });
      }
    } else {
      dispatch(errorMessage("Please select at least one provider to onboard"));
    }
  };

  const onItemCheck = (e, item) => {
    setState((prev) => ({
      ...prev,
      selectedProvider: { ...item, selected: e.target.checked },
    }));
  };

  const handleSearchCountry = (e) => {
    setSearchCountryValue(e.target.value?.toLowerCase());
  };

  const handleSearchProvider = (e) => {
    setSearchProviderValue(e.target.value?.toLowerCase());
  };

  const renderAlertContent = (data) => {
    return (
      <div>
        {data.map((payload, idx) => (
          <div
            key={idx}
            className="d-flex align-items-center justify-content-between"
          >
            <div
              className={`font-weight-bold text-${payload?.data?.requestSuccessful ? "success" : "danger"
                } note-warning`}
            >
              {payload?.data?.requestSuccessful
                ? `Your onboarding ${payload?.data?.responseData?.providerName
                  ? "on" + payload?.data?.responseData?.providerName
                  : ""
                } has been initiated.`
                : payload?.data?.message}
            </div>
            <div className="alert-icon">
              <i
                className={`ri-${payload?.data?.requestSuccessful
                  ? "check-line text-success"
                  : "close-line text-danger"
                  }`}
              ></i>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Modal backdrop="static" isOpen={open} onClosed={onClosed}>
      <ModalHeader
        toggle={selectedCountry ? null : toggle}
        className="no-bb align-items-start d-flex justify-content-between"
        close={
          <button
            onClick={toggle}
            className="bg-transparent text-muted btn p-0"
          >
            <i className="ri-close-fill close_size"></i>
          </button>
        }
      >
        {selectedCountry ? (
          <ProvidersHeader onClick={() => setSelectedCountry(null)} />
        ) : (
          <CountriesHeader />
        )}
      </ModalHeader>
      {!selectedCountry ? (
        <ModalBody className="px-2">
          <div className="row sp__modal-content">
            <SelectCountry
              onSearch={handleSearchCountry}
              countries={state.countryOptions.filter((wine) =>
                Object.values(wine).some((val) =>
                  String(val)?.toLowerCase()?.includes(searchCountryValue)
                )
              )}
              onSelect={(data) => setSelectedCountry(data)}
              selectedCountry={selectedCountry}
              searchValue={searchCountryValue}
            />
          </div>
        </ModalBody>
      ) : (
        <>
          <ModalBody className="pb-0">
            <div className="row sp__modal-content">
              <SelectProviders
                onSearch={handleSearchProvider}
                onCardClick={(e, prov) =>
                  !prov?.isMerchantOnboarded && onItemCheck(e, prov)
                }
                selectedCountry={selectedCountry}
                searchValue={searchProviderValue}
                providers={state?.List?.filter((v) => {
                  return (
                    v.currency?.toLowerCase() === selectedCountry?.toLowerCase()
                  );
                }).filter((wine) =>
                  Object.values(wine).some((val) =>
                    String(val)?.toLowerCase()?.includes(searchProviderValue)
                  )
                )}
                selectedProvider={state.selectedProvider}
                onCheckboxChange={(e, prov) => onItemCheck(e, prov)}
                onOnboardClick={() =>
                  handleOnboardMultiple({
                    selected: state.SelectedList.filter(
                      (v) => !v.isMerchantOnboarded
                    ),
                  })
                }
                onboarding={onboardingLoading}
              />
            </div>
          </ModalBody>
          <ModalFooter className="no-bt">
            <div className="row providers-main__footer fade_in">
              <div className="note">
                <i className="ri-error-warning-line"></i>
                <span className="note-warning">
                  <strong>Note :</strong> Base One would review your request to
                  onboard on each provider bank before you can start creating
                  virtual accounts. This typically takes one business day.
                </span>
              </div>
              <Button
                className="action-btn"
                label={
                  onboardingLoading ? (
                    <Spinner color="primary" size="sm">
                      Loading...
                    </Spinner>
                  ) : (
                    "Onboard"
                  )
                }
                background="blue"
                size="md"
                disabled={onboardingLoading}
                onClick={() => {
                  handleOnboardMultiple({
                    selected: state.SelectedList.filter(
                      (v) => !v.isMerchantOnboarded
                    ),
                  });
                }}
              />
            </div>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default OnboardProvidersModal;
