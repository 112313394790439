import React, { useEffect, useState } from "react";
import { LoadingTableContent } from "components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import _debounce from "lodash.debounce";
import EmptyState from "shared/empty/empty";
import TablePagination from "shared/tablePagination/tablePagination";
import Table from "vgg-ui-react/dist/components/Core/Table";
import FilterDropdown from "shared/FilterDropdown/FilterDropdown";

import "./chargeBack.scss";
import { fetchChargebackData } from "redux/actions/account.action";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import { format } from "date-fns";
import ViewChargebackModal from "./viewChargebackModal/ViewChargebackModal";

const filterOptions = ["All", "Processing", "Successful", "Failed"];

const Chargeback = () => {
  const dispatch = useDispatch();
  const { chargebackPayload, chargebackLoading } = useSelector(({ account }) => account);
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);

  useEffect(() => {
    dispatch(fetchChargebackData({ ...page }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleSearch = _debounce(event => {
    const search = event.target.value;
    dispatch(fetchChargebackData({ ...page, search }));
  }, 1000);

  return (
    <div className="chargeback-content">
      <div className="px-3 mt-3 d-flex align-items-center border-bottom py-2">
        <Typography typographyVariant="section-title" text="List of applied chargeback" className="font-weight-500" />
      </div>
      <div className="virtual-account-table border-bottom px-3">
        <div className="sp-mb--24 sp-mt--24 d-flex align-items-center justify-content-between">
          <div>
            <div className="search-container">
              <input type="text" placeholder="Search" className="form-control" onChange={handleSearch} />
              <i className="ri-search-line"></i>
            </div>
          </div>
          <FilterDropdown
            buttonContent={selectedFilter !== "All" && selectedFilter}
            items={filterOptions}
            onItemClick={item => {
              setSelectedFilter(item);
              dispatch(fetchChargebackData({ ...page, status: item === "All" ? "" : item.toUpperCase() }));
            }}
          />
        </div>
        <Table cellVariants="default" className="table-striped">
          <thead>
            <tr>
              <th width="20%">Transaction Reference</th>
              <th width="20%">Merchant Name</th>
              <th width="20%">Chargeback Type</th>
              <th width="15%">Date Created</th>
              <th width="15%"></th>
            </tr>
          </thead>
          {chargebackLoading ? (
            <tbody style={{ borderTop: "none" }}>
              <LoadingTableContent colSpan={5} />
            </tbody>
          ) : (
            <tbody style={{ borderTop: "none" }}>
              {chargebackPayload?.data?.responseData?.items &&
                chargebackPayload?.data?.responseData?.items?.map(val => (
                  <tr key={val?.id}>
                    <td>{val?.transactionReference || "--"}</td>
                    <td>{val?.merchantName || "--"}</td>
                    <td>{val?.chargeType || "--"}</td>
                    <td>{val?.createdAt ? format(new Date(val?.createdAt), "dd/MM/yyyy") : "--"}</td>
                    <td style={{ textAlign: "right" }}>
                      <span
                        className="action mr-2"
                        onClick={() => {
                          setSelectedRow(val);
                        }}
                      >
                        View
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      </div>

      {!chargebackLoading && chargebackPayload?.data?.responseData?.items?.length < 1 ? <EmptyState /> : null}
      {chargebackPayload?.data?.responseData?.items && chargebackPayload?.data?.responseData?.items?.length > 0 && (
        <div className="px-3">
          <TablePagination
            data={chargebackPayload?.data?.responseData?.items}
            totalCount={chargebackPayload?.data?.responseData?.count}
            currentPage={chargebackPayload?.data?.responseData?.pageNumber}
            pageSize={chargebackPayload?.data?.responseData?.pageSize}
            onPageChange={page => setPage(prev => ({ ...prev, pageNumber: page }))}
            totalPages={chargebackPayload?.data?.responseData?.pages}
          />
        </div>
      )}
      <ViewChargebackModal
        data={selectedRow}
        toggle={() => {
          if (selectedRow) {
            setSelectedRow(null);
          }
        }}
        open={!!selectedRow}
      />
    </div>
  );
};

export default Chargeback;
