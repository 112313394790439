import React from "react";
import noData from "../../assets/images/noData.svg";
import "./empty.scss";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import { Button } from "reactstrap";

const EmptyState = ({ history, title, showMessage=true, handler, btnName, message }) => {
  return (
      <div className="empty-container">
        <div>
          {!btnName?<div className="sp-mb--24">
            <img src={noData} alt="empty" />
          </div>:null}
          <div className="text-center sp-mb--16">
            <div className="sp-mb--8">
              <Typography typographyVariant="subtitle1" text={title||"Nothing here"} className="font-weight-bold" />
            </div>
            {showMessage?<div>
              <Typography typographyVariant="caption" text={message || "Add a user to configure options"} className="sp-text-color--neutral-400" />
            </div>:null}
            {btnName?<Button color="primary" className="btn btn-sm">
                          <span className=" fw-bold" onClick={handler}>{btnName}</span>
                        </Button>:null}
          </div>
        </div>
      </div>
  );
};

export default EmptyState;
