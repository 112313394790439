import BarChartOverview from "components/BarChartOverview/BarChartOverview";
import BottomCards from "components/BottomCards/BottomCards";
import TopCards from "components/TopCards/TopCards";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { fetchAllProviders, getReports } from "redux/actions/account.action";
import FilterDropdown from "shared/FilterDropdown/FilterDropdown";
import Button from "vgg-ui-react/dist/components/Core/Button";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import "./overview.scss";
import swal from "@sweetalert/with-react";
import OnboardProvidersModal from "components/OnboardProvidersModal/OnboardProvidersModal";
import SuccessModal from "components/SuccessModal/SuccessModal";
import { getUserDetails } from "apiServices/user.service";


const Overview = () => {
  const { Client_Identifier } = getUserDetails()?.accessToken ? jwt_decode(getUserDetails()?.accessToken) : {};
  const dispatch = useDispatch();
  const [statusContent, setStatusContent] = useState(null);
  const { reportsLoading, reportsPayload, loadingAllProviders, allProviders } = useSelector(({ account }) => account);

  const [dateRange, setDateRange] = useState(null);
  const [remount, setRemount] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(
      getReports({
        currency: "NGN",
        startDate: dateRange ? dateRange?.start?.format("YYYY-MM-DD") : null,
        endDate: dateRange ? dateRange?.end?.format("YYYY-MM-DD") : null,
      })
    );
    if (remount) {
      setRemount(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const onSelect = value => {
    const start = value.start;
    const end = value.end;
    const dateCount = end.diff(start, "days") + 1;
    if (dateCount > 30) {
      swal({
        text: "Kindly select range to be at most 30 days.",
        icon: "info",
        button: "Okay",
      });
    } else {
      setDateRange(value);
    }
  };

  const onClearSelection = () => {
    setDateRange(null);
  };

  const renderContent = range => {
    return <>{!range ? "Monthly" : `${range?.start?.format("LL")} - ${range?.end?.format("LL")}`}</>;
  };

  return (
    <div style={{ height: "100%" }} className="fade_in">
      <div className="sp-py--32 w-100 d-flex align-items-center justify-content-between">
        <Typography typographyVariant="disp-heading" text="Analytics" className="font-weight-bold" />
        <div className="d-flex align-items-center justify-content-end">
          <FilterDropdown buttonContent={renderContent(dateRange)}>
            {!remount && <DateRangePicker numberOfCalendars={2} value={dateRange} onSelect={onSelect} className="virtual-account" />}
            <div className="py-2 px-3">
              {dateRange && (
                <Button
                  label="Clear Selection"
                  background="blue"
                  className="w-100"
                  onClick={() => {
                    setRemount(true);
                    onClearSelection();
                  }}
                />
              )}
            </div>
          </FilterDropdown>
          <div className="ms-2">
            <Button label="Onboard Provider" background="blue" onClick={() => setOpen(true)} />
          </div>
        </div>
      </div>

      <div className="sp-pt--32">
        <TopCards data={reportsPayload?.data?.responseData} />
      </div>

      <div className="sp-pt--32">
        <BarChartOverview
          loading={reportsLoading}
          dataSource={reportsPayload?.data?.responseData?.creditNotifications}
          totalReceived={reportsPayload?.data?.responseData?.totalCreditedAmountRecieved}
        />
      </div>
      <div className="sp-pt--32">
        <BottomCards data={reportsPayload?.data?.responseData} loading={loadingAllProviders} activeProviders={allProviders?.data?.responseData?.items?.filter(v => v.isActive) || []} />
      </div>

      <OnboardProvidersModal
        open={open}
        toggle={() => setOpen(!open)}
        onClosed={() => setOpen(false)}
        onSuccess={(data) => {
          setStatusContent(data);
          dispatch(
            getReports({
              currency: "NGN",
              startDate: dateRange ? dateRange?.start?.format("YYYY-MM-DD") : null,
              endDate: dateRange ? dateRange?.end?.format("YYYY-MM-DD") : null,
            })
          )
        }
        }
      />

      <SuccessModal
        open={statusContent?.fieldChildren || statusContent?.successChildren}
        statusText={statusContent}
        failed={statusContent?.failed}
        onClose={() => {
          if (statusContent?.failed && statusContent?.succeed) {
            setStatusContent(prev => ({ ...prev, fai: [] }));
          } else {
            setOpen(false);
            setStatusContent(null);
            dispatch(fetchAllProviders(Client_Identifier));
          }
        }}
      >
        {statusContent?.failedChildren || statusContent?.successChildren}
      </SuccessModal>
    </div>
  );
};

export default Overview;
