import swal from "@sweetalert/with-react";
import BankDetailsModal from "components/BankDetailsModal/BankDetailsModal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Input, Label, Spinner } from "reactstrap";
import { fetchMerchant, fetchSupportedBanks, updateSettings } from "redux/actions/account.action";
import Typography from "vgg-ui-react/dist/components/Core/Typography";

const Settings = () => {
  const dispatch = useDispatch();
  const { isUpdating, updateSuccess, merchantPayload, fetchingMerchant, supportedBanks } = useSelector(({ account }) => account);

  const [value, setValue] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [updateTriggered, setUpdateTriggered] = useState(false);
  const [openBankForm, setOpenBankForm] = useState(false);

  useEffect(() => {
    dispatch(fetchMerchant());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapData = settlementType => {
    let val;
    if (typeof settlementType === "string") {
      if (settlementType?.toLowerCase() === "corporatewallet") {
        val = 1;
      } else if (settlementType?.toLowerCase() === "subaccount") {
        val = 2;
      } else if (settlementType?.toLowerCase() === "bank") {
        val = 3;
      }
      setValue(val);
    } else if (typeof settlementType === "number") {
      setValue(+settlementType);
    }
  };

  useEffect(() => {
    if (merchantPayload) {
      mapData(merchantPayload?.responseData?.settlementType);
      dispatch(fetchSupportedBanks({ countryCode: merchantPayload?.responseData?.countryCode || "NGA" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantPayload]);

  useEffect(() => {
    if (!isUpdating && updateSuccess && submitted) {
      swal({
        text: updateSuccess?.message || "Update successful",
        icon: "success",
        button: "Okay",
      }).then(() => {
        dispatch(fetchMerchant());
        setSubmitted(false);
        setOpenBankForm(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess, isUpdating]);

  useEffect(() => {
    if (updateTriggered && value !== 3) {
      const payload = {
        settlementType: value,
        id: merchantPayload.responseData?.id,
        merchantName: merchantPayload.responseData?.merchantName,
      };
      dispatch(updateSettings({ payload }));
      setUpdateTriggered(false);
      setSubmitted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, updateTriggered]);

  const handleSubmitBankDetails = data => {
    const bank = supportedBanks?.data?.responseData?.find(bk => data.bankCode === bk.bankCode);
    const payload = {
      settlementType: 3,
      id: merchantPayload.responseData?.id,
      merchantName: merchantPayload.responseData?.merchantName,
      bankName: bank.bankName,
      bankCode: data.bankCode,
      accountNumber: data.accountNumber,
      accountName: data.accountName,
    };
    dispatch(updateSettings({ payload }));
    setUpdateTriggered(false);
    setSubmitted(true);
  };

  return (
    <div>
      <div className="sp-mt--24 sp-mb--16">
        <Typography typographyVariant="section-title" text="Preferences" className="font-weight-bold" />
      </div>
      <div className="d-flex align-items-center">
        <Typography typographyVariant="caption" text="Settlement Preference" className="font-weight-bold me-3 align-self-start" />
        {!fetchingMerchant && typeof value === "number" && (
          <div>
            <FormGroup check className="d-flex flex-column">
              <Label check>
                <Input
                  type="radio"
                  name="settlementType"
                  id="1"
                  checked={value === 1}
                  onChange={e => {
                    setValue(+e.target.id);
                    setUpdateTriggered(true);
                  }}
                />{" "}
                Settle to Corporate Wallet{" "}
                {isUpdating && value === 1 && (
                  <Spinner
                    color="primary"
                    type="grow"
                    size="sm"
                    className="ms-5"
                  >
                    Loading...
                  </Spinner>
                )}
              </Label>
              <Label check>
                <Input
                  type="radio"
                  name="settlementType"
                  id="2"
                  checked={value === 2}
                  onChange={e => {
                    setValue(+e.target.id);
                    setUpdateTriggered(true);
                  }}
                />
                Leave in Sub Account
                {isUpdating && value === 2 && (
                  <Spinner
                    color="primary"
                    type="grow"
                    size="sm"
                    className="ms-5"
                  >
                    Loading...
                  </Spinner>
                )}
              </Label>
              <Label check>
                <Input
                  type="radio"
                  name="settlementType"
                  id="3"
                  checked={value === 3}
                  onChange={e => {
                    setOpenBankForm(true);
                  }}
                />{" "}
                Settle to Bank Account{" "}
                {isUpdating && value === 3 && (
                  <Spinner
                    color="primary"
                    type="grow"
                    size="sm"
                    className="ms-5"
                  >
                    Loading...
                  </Spinner>
                )}
              </Label>
            </FormGroup>
          </div>
        )}
      </div>

      <BankDetailsModal
        open={openBankForm}
        onClose={() => setOpenBankForm(false)}
        onSubmit={handleSubmitBankDetails}
        banks={supportedBanks?.data?.responseData?.map(bank => ({ label: bank.bankName, value: bank.bankCode })) || []}
        loading={isUpdating}
        toggle={() => {
          mapData(merchantPayload?.responseData?.settlementType);
          setOpenBankForm(!openBankForm);
        }}
      />
    </div>
  );
};

export default Settings;
