import { Type } from "../actions/account.action";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INTIAL_STATE = {
  transactionsPayload: {
    data: {
      responseData: {
        items: [],
      },
    },
  },
};

const onboardingReducer = (state = INTIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(Type.ONBOARDING):
      return {
        ...state,
        onboardingLoading: true,
        onboardingPayload: null,
        onboardingError: null,
      };
    case SUCCESS(Type.ONBOARDING):
      return {
        ...state,
        onboardingPayload: actions.payload,
        onboardingError: null,
        onboardingLoading: false,
      };
    case FAILURE(Type.ONBOARDING):
      return {
        ...state,
        onboardingError: actions.payload,
        onboardingPayload: null,
        onboardingLoading: false,
      };

    case REQUEST(Type.TRANSACTIONS):
      return {
        ...state,
        transactionsLoading: true,
        transactionsPayload: null,
        transactionsError: null,
      };
    case SUCCESS(Type.TRANSACTIONS):
      return {
        ...state,
        transactionsPayload: actions.payload,
        transactionsError: null,
        transactionsLoading: false,
      };
    case FAILURE(Type.TRANSACTIONS):
      return {
        ...state,
        transactionsError: actions.payload,
        transactionsPayload: null,
        transactionsLoading: false,
      };

    case REQUEST(Type.CHECK_MERCHANT_STATUS):
      return {
        ...state,
        checkingMerchantStatus: true,
        merchantStatus: null,
        merchantStatusError: null,
      };
    case SUCCESS(Type.CHECK_MERCHANT_STATUS):
      return {
        ...state,
        checkingMerchantStatus: false,
        merchantStatus: actions.payload,
        merchantStatusError: null,
      };
    case FAILURE(Type.CHECK_MERCHANT_STATUS):
      return {
        ...state,
        merchantStatus: null,
        checkingMerchantStatus: false,
        merchantStatusError: actions.payload,
      };

    case REQUEST(Type.ONBOARD_MULTIPLE):
      return {
        ...state,
        onboardingMultiple: true,
        onboardMultiplePayload: null,
        onboardMultipleError: null,
      };
    case SUCCESS(Type.ONBOARD_MULTIPLE):
      return {
        ...state,
        onboardingMultiple: false,
        onboardMultiplePayload: actions.payload,
        onboardMultipleError: null,
      };
    case FAILURE(Type.ONBOARD_MULTIPLE):
      return {
        ...state,
        onboardMultiplePayload: null,
        onboardingMultiple: false,
        onboardMultipleError: actions.payload,
      };

    case REQUEST(Type.PROVIDER_LIST):
      return {
        ...state,
        providerListLoading: true,
        providerListPayload: null,
        providerListError: null,
      };
    case SUCCESS(Type.PROVIDER_LIST):
      return {
        ...state,
        providerListPayload: actions.payload,
        providerListError: null,
        providerListLoading: false,
      };
    case FAILURE(Type.PROVIDER_LIST):
      return {
        ...state,
        providerListError: actions.payload,
        providerListPayload: null,
        providerListLoading: false,
      };

    case REQUEST(Type.REPORTS):
      return {
        ...state,
        reportsLoading: true,
        reportsPayload: null,
        reportsError: null,
      };
    case SUCCESS(Type.REPORTS):
      return {
        ...state,
        reportsPayload: actions.payload,
        reportsError: null,
        reportsLoading: false,
      };
    case FAILURE(Type.REPORTS):
      return {
        ...state,
        reportsError: actions.payload,
        reportsPayload: null,
        reportsLoading: false,
      };

    case REQUEST(Type.VIRTUAL_ACCOUNT):
      return {
        ...state,
        virtualAccountLoading: true,
        virtualAccountPayload: null,
        virtualAccountError: null,
      };
    case SUCCESS(Type.VIRTUAL_ACCOUNT):
      return {
        ...state,
        virtualAccountPayload: actions.payload,
        virtualAccountError: null,
        virtualAccountLoading: false,
      };
    case FAILURE(Type.VIRTUAL_ACCOUNT):
      return {
        ...state,
        virtualAccountError: actions.payload,
        virtualAccountPayload: null,
        virtualAccountLoading: false,
      };

    case REQUEST(Type.COUNTRIES):
      return {
        ...state,
        loadingCountries: true,
        countriesPayload: null,
        countriesError: null,
      };
    case SUCCESS(Type.COUNTRIES):
      return {
        ...state,
        countriesPayload: actions.payload,
        countriesError: null,
        loadingCountries: false,
      };
    case FAILURE(Type.COUNTRIES):
      return {
        ...state,
        countriesError: actions.payload,
        countriesPayload: null,
        loadingCountries: false,
      };

    case REQUEST(Type.ALL_PROVIDERS):
      return {
        ...state,
        loadingAllProviders: true,
        allProviders: null,
        allProvidersError: null,
      };
    case SUCCESS(Type.ALL_PROVIDERS):
      return {
        ...state,
        allProviders: actions.payload,
        allProvidersError: null,
        loadingAllProviders: false,
      };
    case FAILURE(Type.ALL_PROVIDERS):
      return {
        ...state,
        allProvidersError: actions.payload,
        allProviders: null,
        loadingAllProviders: false,
      };
    case REQUEST(Type.FETCH_SUPPORTED_BANKS):
      return {
        ...state,
        fetchingSupportedBanks: true,
        supportedBanks: null,
        supportedBanksError: null,
      };
    case SUCCESS(Type.FETCH_SUPPORTED_BANKS):
      return {
        ...state,
        supportedBanks: actions.payload,
        supportedBanksError: null,
        fetchingSupportedBanks: false,
      };
    case FAILURE(Type.FETCH_SUPPORTED_BANKS):
      return {
        ...state,
        supportedBanksError: actions.payload,
        supportedBanks: null,
        fetchingSupportedBanks: false,
      };
    case REQUEST(Type.GET_ACCOUNTS):
      return {
        ...state,
        getAccountsSuccess: null,
        getAccountsError: null,
        isGettingAccounts: true,
      };
    case SUCCESS(Type.GET_ACCOUNTS):
      return {
        ...state,
        isGettingAccounts: false,
        getAccountsSuccess: actions.payload.data,
        getAccountsError: null,
      };
    case FAILURE(Type.GET_ACCOUNTS):
      return {
        ...state,
        isGettingAccounts: false,
        getAccountsSuccess: null,
        getAccountsError: actions.payload,
      };
    case REQUEST(Type.GET_ACCOUNT_DETAIL):
      return {
        ...state,
        getAccountDetailSuccess: null,
        getAccountDetailError: null,
        getAccountDetailLoading: true,
      };
    case SUCCESS(Type.GET_ACCOUNT_DETAIL):
      return {
        ...state,
        getAccountDetailLoading: false,
        getAccountDetailSuccess: actions.payload.data,
        getAccountDetailError: null,
      };
    case FAILURE(Type.GET_ACCOUNT_DETAIL):
      return {
        ...state,
        getAccountDetailLoading: false,
        getAccountDetailSuccess: null,
        getAccountDetailError: actions.payload,
      };
    case REQUEST(Type.GET_TRANSFERS):
      return {
        ...state,
        isGettingTransfers: true,
        getTransfersSuccess: null,
        getTransfersError: null,
      };
    case SUCCESS(Type.GET_TRANSFERS):
      return {
        ...state,
        isGettingTransfers: false,
        getTransfersSuccess: actions.payload,
        getTransfersError: null,
      };
    case FAILURE(Type.GET_TRANSFERS):
      return {
        ...state,
        isGettingTransfers: false,
        getTransfersSuccess: null,
        getTransfersError: actions.payload,
      };
    case REQUEST(Type.FETCH_CHARGEBACK_DATA):
      return {
        ...state,
        chargebackLoading: true,
        chargebackPayload: null,
        getTransfersError: null,
      };
    case SUCCESS(Type.FETCH_CHARGEBACK_DATA):
      return {
        ...state,
        chargebackLoading: false,
        chargebackPayload: actions.payload,
        getTransfersError: null,
      };
    case FAILURE(Type.FETCH_CHARGEBACK_DATA):
      return {
        ...state,
        chargebackLoading: false,
        chargebackPayload: null,
        getTransfersError: actions.payload,
      };
    case REQUEST(Type.GET_BANKS):
      return {
        ...state,
        isGettingBanks: true,
        getBanksSuccess: null,
        getBanksError: null,
      };
    case SUCCESS(Type.GET_BANKS):
      return {
        ...state,
        isGettingBanks: false,
        getBanksSuccess: actions.payload.data,
        getBanksError: null,
      };
    case FAILURE(Type.GET_BANKS):
      return {
        ...state,
        isGettingBanks: false,
        getBanksSuccess: null,
        getBanksError: actions.payload,
      };
    case REQUEST(Type.GET_STATIC_ACCOUNTS):
      return {
        ...state,
        isGettingStaticAccounts: true,
        getStaticAccountsSuccess: null,
        getStaticAccountsError: null,
      };
    case SUCCESS(Type.GET_STATIC_ACCOUNTS):
      return {
        ...state,
        isGettingStaticAccounts: false,
        getStaticAccountsSuccess: actions.payload.data,
        getStaticAccountsError: null,
      };
    case FAILURE(Type.GET_STATIC_ACCOUNTS):
      return {
        ...state,
        isGettingStaticAccounts: false,
        getStaticAccountsSuccess: null,
        getStaticAccountsError: actions.payload,
      };

    case REQUEST(Type.FETCH_COLLECTION_WALLETS):
      return {
        ...state,
        fetchingCollectionWallets: true,
        conllectionWalletPayload: null,
        collectionWalletError: null,
      };
    case SUCCESS(Type.FETCH_COLLECTION_WALLETS):
      return {
        ...state,
        fetchingCollectionWallets: false,
        conllectionWalletPayload: actions.payload.data,
        collectionWalletError: null,
      };
    case FAILURE(Type.FETCH_COLLECTION_WALLETS):
      return {
        ...state,
        fetchingCollectionWallets: false,
        conllectionWalletPayload: null,
        collectionWalletError: actions.payload,
      };

    case REQUEST(Type.FETCH_SETTLEMENTS):
      return {
        ...state,
        fetchingSettlements: true,
        settlementsPayload: null,
        settlementsError: null,
      };
    case SUCCESS(Type.FETCH_SETTLEMENTS):
      return {
        ...state,
        fetchingSettlements: false,
        settlementsPayload: actions.payload.data,
        settlementsError: null,
      };
    case FAILURE(Type.FETCH_SETTLEMENTS):
      return {
        ...state,
        fetchingSettlements: false,
        settlementsPayload: null,
        settlementsError: actions.payload,
      };

    case REQUEST(Type.FETCH_SETTLEMENT_TRANSACTIONS):
      return {
        ...state,
        fetchingSettlementTransactions: true,
        settlementTransactionsPayload: null,
        settlementTransactionsError: null,
      };
    case SUCCESS(Type.FETCH_SETTLEMENT_TRANSACTIONS):
      return {
        ...state,
        fetchingSettlementTransactions: false,
        settlementTransactionsPayload: actions.payload.data,
        settlementTransactionsError: null,
      };
    case FAILURE(Type.FETCH_SETTLEMENT_TRANSACTIONS):
      return {
        ...state,
        fetchingSettlementTransactions: false,
        settlementTransactionsPayload: null,
        settlementTransactionsError: actions.payload,
      };
    case REQUEST(Type.INTER_TRANSFER):
      return {
        ...state,
        interTransferLoading: true,
        interTransferSuccess: null,
        interTransferError: null,
      };
    case SUCCESS(Type.INTER_TRANSFER):
      return {
        ...state,
        interTransferSuccess: actions.payload.data,
        interTransferError: null,
        interTransferLoading: false,
      };
    case FAILURE(Type.INTER_TRANSFER):
      return {
        ...state,
        interTransferError: actions.payload,
        interTransferSuccess: null,
        interTransferLoading: false,
      };
    case REQUEST(Type.NEW_ACCOUNT):
      return {
        ...state,
        newAccountLoading: true,
        newAccountSuccess: null,
        newAccountError: null,
      };
    case SUCCESS(Type.NEW_ACCOUNT):
      return {
        ...state,
        newAccountSuccess: actions.payload.data,
        newAccountError: null,
        newAccountLoading: false,
      };
    case FAILURE(Type.NEW_ACCOUNT):
      return {
        ...state,
        newAccountError: actions.payload,
        newAccountSuccess: null,
        newAccountLoading: false,
      };
    case REQUEST(Type.PROVIDER_COUNTRY):
      return {
        ...state,
        providerCountryLoading: true,
        providerCountrySuccess: null,
        providerCountryError: null,
      };
    case SUCCESS(Type.PROVIDER_COUNTRY):
      return {
        ...state,
        providerCountrySuccess: actions.payload.data,
        providerCountryError: null,
        providerCountryLoading: false,
      };
    case FAILURE(Type.PROVIDER_COUNTRY):
      return {
        ...state,
        providerCountryError: actions.payload,
        providerCountrySuccess: null,
        providerCountryLoading: false,
      };
    case REQUEST(Type.PROVIDERS):
      return {
        ...state,
        providerLoading: true,
        providerSuccess: null,
        providerError: null,
      };
    case SUCCESS(Type.PROVIDERS):
      return {
        ...state,
        providerSuccess: actions.payload.data,
        providerError: null,
        providerLoading: false,
      };
    case FAILURE(Type.PROVIDERS):
      return {
        ...state,
        providerError: actions.payload,
        providerSuccess: null,
        providerLoading: false,
      };
    case REQUEST(Type.ACCOUNT_TYPES):
      return {
        ...state,
        accountTypesLoading: true,
        accountTypesSuccess: null,
        accountTypesError: null,
      };
    case SUCCESS(Type.ACCOUNT_TYPES):
      return {
        ...state,
        accountTypesSuccess: actions.payload.data,
        accountTypesError: null,
        accountTypesLoading: false,
      };
    case FAILURE(Type.ACCOUNT_TYPES):
      return {
        ...state,
        accountTypesError: actions.payload,
        accountTypesSuccess: null,
        accountTypesLoading: false,
      };
    case REQUEST(Type.UPDATE_SETTINGS):
      return {
        ...state,
        updateSuccess: null,
        updateError: null,
        isUpdating: true,
      };
    case SUCCESS(Type.UPDATE_SETTINGS):
      return {
        ...state,
        isUpdating: false,
        updateSuccess: actions.payload.data,
        updateError: null,
      };
    case FAILURE(Type.UPDATE_SETTINGS):
      return {
        ...state,
        isUpdating: false,
        updateSuccess: null,
        updateError: actions.payload,
      };
    case REQUEST(Type.FETCH_MERCHANT):
      return {
        ...state,
        merchantPayload: null,
        merchantError: null,
        fetchingMerchant: true,
      };
    case SUCCESS(Type.FETCH_MERCHANT):
      return {
        ...state,
        fetchingMerchant: false,
        merchantPayload: actions.payload.data,
        merchantError: null,
      };
    case FAILURE(Type.FETCH_MERCHANT):
      return {
        ...state,
        fetchingMerchant: false,
        merchantPayload: null,
        merchantError: actions.payload,
      };
    case REQUEST(Type.RESOLVE_BANK_ACCOUNT):
      return {
        ...state,
        resolveBankAccountLoading: true,
        resolveBankAccountSuccess: null,
        resolveBankAccountError: null,
      };
    case SUCCESS(Type.RESOLVE_BANK_ACCOUNT):
      return {
        ...state,
        resolveBankAccountLoading: false,
        resolveBankAccountSuccess: actions.payload.data,
        resolveBankAccountError: null,
      };
    case FAILURE(Type.RESOLVE_BANK_ACCOUNT):
      return {
        ...state,
        resolveBankAccountLoading: false,
        resolveBankAccountSuccess: null,
        resolveBankAccountError: actions.payload,
      };
      case REQUEST(Type.WEBHOOK_NOTIFICATION):
      return {
        ...state,
        webhookNotificationSuccess: null,
        webhookNotificationError: null,
        webhookNotificationLoading: true,
      };
    case SUCCESS(Type.WEBHOOK_NOTIFICATION):
      return {
        ...state,
        webhookNotificationLoading: false,
        webhookNotificationSuccess: actions.payload,
        webhookNotificationError: null,
      };
    case FAILURE(Type.WEBHOOK_NOTIFICATION):
      return {
        ...state,
        webhookNotificationLoading: false,
        webhookNotificationSuccess: null,
        webhookNotificationError: actions.payload,
      };
    
    default:
      return { ...state };
  }
};

export default onboardingReducer;
