import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import _debounce from "lodash.debounce";
import Table from "vgg-ui-react/dist/components/Core/Table";
import { NumericFormat } from "react-number-format";
import getSymbolFromCurrency from "currency-symbol-map";

import FilterDropdown from "shared/FilterDropdown/FilterDropdown";
import BackButton from "pages/NavigationButton/BackButton";
import { useQueryParams } from "helpers/hooks";
import EmptyState from "shared/empty/empty";
import TablePagination from "shared/tablePagination/tablePagination";
import { fetchSettlementTransactions } from "redux/actions/account.action";
import "./settlements.scss";
import { LoadingTableContent } from "components/loader/Loader";
import { format } from "date-fns";
import URLS from "helpers/urls";

const SettledTransactions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useQueryParams();
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const batchId = params.get("batch");
  const { fetchingSettlementTransactions, settlementTransactionsPayload } =
    useSelector(({ account }) => {
      return {
        fetchingSettlementTransactions: account?.fetchingSettlementTransactions,
        settlementTransactionsPayload: account?.settlementTransactionsPayload,
      };
    });

  useEffect(() => {
    if (batchId) {
      dispatch(fetchSettlementTransactions({ batchId, pageNumber }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchId]);

  useEffect(() => {
    if (pageNumber) {
      dispatch(fetchSettlementTransactions({ batchId, pageNumber }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const handleSearch = _debounce((event) => {
    const keyword = event.target.value;
    dispatch(fetchSettlementTransactions({ batchId, pageNumber: 1, keyword }));
  }, 1000);

  return (
    <div className="service-details mb-5">
      <BackButton
        label="Settlement"
        onClick={() => history.push(URLS.Settlements, { isBack: true })}
        iconClassName="ri-2x"
      />

      <section
        className="content-section sp-pt--24"
        data-testid="content-section"
      >
        <div className="settlement-page-container">
          <div className="border-bottom p-3">
            <h4 className="page-title">Transactions</h4>
          </div>
          <div className="border-bottom px-3 pt-3">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="search-container">
                <input
                  type="search"
                  placeholder="search"
                  className="form-control"
                  data-testid="search-input"
                  onChange={handleSearch}
                />
                <i className="ri-search-line"> </i>
              </div>
              <div className="d-flex align-items-center">
                {/* <FilterDropdown
                  items={["COPORATEWALLET", "BANK", "SUBACCOUNT"]}
                  dropdownOpen={showFilterDropdown}
                  onItemClick={(val) =>
                    dispatch(
                      fetchSettlementTransactions({
                        batchId,
                        pageNumber: 1,
                        settlementType: val,
                      })
                    )
                  }
                  toggleDropdown={() => setShowFilterDropdown((prev) => !prev)}
                /> */}
              </div>
            </div>

            <Table cellVariants="default" className="table-striped">
              <thead>
                <tr>
                  <th>Acct. Number</th>
                  <th>Amount</th>
                  <th>Currency</th>
                  <th>Reference</th>
                  <th>Date Created</th>
                </tr>
              </thead>
              {fetchingSettlementTransactions ? (
                <tbody style={{ borderTop: "none" }}>
                  <LoadingTableContent colSpan={5} />
                </tbody>
              ) : (
                <>
                  {settlementTransactionsPayload?.responseData?.items?.length >
                    0 && (
                    <tbody style={{ borderTop: "none" }}>
                      {settlementTransactionsPayload?.responseData?.items?.map(
                        (account) => {
                          return (
                            <tr key={account.id}>
                              <td>{account.accountNumber || "-"}</td>
                              <td>
                                <NumericFormat
                                  value={account.amount}
                                  displayType="text"
                                  thousandSeparator
                                  prefix={getSymbolFromCurrency(
                                    account?.currency
                                  )}
                                  renderText={(value) => (
                                    <span>{value || "-"}</span>
                                  )}
                                  decimalScale={2}
                                  fixedDecimalScale
                                />
                              </td>
                              <td>{account.currency || "-"}</td>
                              <td>{account.reference || "-"}</td>
                              <td>
                                {account.transactionDate
                                  ? format(
                                      new Date(account.transactionDate),
                                      "dd/MM/yyyy"
                                    )
                                  : "-"}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  )}
                </>
              )}
            </Table>
          </div>
          {!fetchingSettlementTransactions &&
            !settlementTransactionsPayload?.responseData?.items.length && (
              <EmptyState />
            )}
          {settlementTransactionsPayload?.responseData?.items?.length > 0 && (
            <div className="px-3">
              <TablePagination
                data={settlementTransactionsPayload?.responseData?.items}
                totalCount={settlementTransactionsPayload?.responseData?.count}
                currentPage={
                  settlementTransactionsPayload?.responseData?.pageNumber
                }
                pageSize={settlementTransactionsPayload?.responseData?.pageSize}
                onPageChange={(page) => {
                  setPageNumber(page);
                }}
                totalPages={settlementTransactionsPayload?.responseData?.pages}
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default SettledTransactions;
