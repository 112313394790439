import React, { useEffect, useState } from "react";
import { LoadingTableContent } from "components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import _debounce from "lodash.debounce";
import EmptyState from "shared/empty/empty";
import TablePagination from "shared/tablePagination/tablePagination";
import Table from "vgg-ui-react/dist/components/Core/Table";
import FilterDropdown from "shared/FilterDropdown/FilterDropdown";
import WebhookNotification from "./webhookNotification/webhookNotification";

import "./transactions.scss";
import { fetchTransactions } from "redux/actions/account.action";
import { MoreItemIcon } from "shared/notifications/icons";
import ViewModal from "./viewModal/ViewModal";

const Transactions = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(0);
  const [viewWebhook, setViewWebhook] = useState(false);
  const [reference, setReference] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const { transactionsPayload, transactionsLoading } = useSelector(
    ({ account }) => account
  );
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  useEffect(() => {
    dispatch(fetchTransactions({ ...page }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleSearch = _debounce((event) => {
    const search = event.target.value;
    dispatch(fetchTransactions({ ...page, search }));
  }, 1000);

  return (
    <div className="table-wrapper">
      <div className="border-bottom p-3">
        <h4 className="page-title">Transactions</h4>
      </div>
      <div className="virtual-account-table border-bottom p-3">
        <div className="sp-mb--24 sp-mt--24 d-flex align-items-center justify-content-between">
          <div>
            <div className="search-container">
              <input
                type="text"
                placeholder="Search"
                className="form-control"
                onChange={handleSearch}
              />
              <i className="ri-search-line"></i>
            </div>
          </div>
          <FilterDropdown />
        </div>
        <div className="table-responsive">
          <Table cellVariants="default" className="table-striped">
            <thead>
              <tr>
                <th width="20%">Acc. Number</th>
                <th width="20%">Bank Name</th>
                <th width="20%">Trans. Amt</th>
                <th width="10%">Reference</th>
                <th width="15%">Rec. Account</th>
                <th width="15%">Date</th>
                <th width="15%">Time</th>
                <th width="15%"></th>
              </tr>
            </thead>
            {transactionsLoading ? (
              <tbody style={{ borderTop: "none" }}>
                <LoadingTableContent colSpan={7} />
              </tbody>
            ) : (
              <tbody style={{ borderTop: "none" }}>
                {transactionsPayload?.data?.responseData?.items &&
                  transactionsPayload?.data?.responseData?.items?.map((val) => (
                    <tr key={val?.transactionReference}>
                      <td>
                        <span className="fw-normal">
                          {val?.sourceAccount || "--"}
                        </span>
                      </td>
                      <td>{val?.sourceBankName || "--"}</td>
                      <td>{val?.totalAmount || "--"}</td>
                      <td>{val?.transactionReference || "--"}</td>
                      <td>{val?.beneficiaryAccount || "--"}</td>
                      <td>{val?.transactionDate?.split(" ")[0] || "--"}</td>
                      <td>{val?.transactionDate?.split(" ")[1] || "--"}</td>
                      <td>
                        <div
                          className="options"
                          onMouseLeave={() => setShow(0)}
                          onMouseEnter={() => setShow(val.transactionReference)}
                        >
                          <MoreItemIcon />
                          {show === val.transactionReference && (
                            <div className="more-options d-flex flex-column align-items-start">
                              <button
                                className="btn btn-sm option w-100 d-flex align-items-start justify-content-between"
                                onClick={() => {
                                  setSelectedRow(val);
                                  setReference(val?.transactionReference);
                                }}
                              >
                                View
                              </button>
                              <button
                                className="btn btn-sm option w-100 d-flex align-items-start justify-content-between"
                                onClick={() => {
                                  setViewWebhook(true);
                                  setReference(val?.transactionReference);
                                }}
                              >
                                Renotify
                              </button>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </Table>
        </div>
      </div>
      {!transactionsLoading &&
      transactionsPayload?.data?.responseData?.items?.length < 1 ? (
        <EmptyState />
      ) : null}
      {transactionsPayload?.data?.responseData?.items &&
        transactionsPayload?.data?.responseData?.items?.length > 0 && (
          <div className="px-3">
            <TablePagination
              data={transactionsPayload?.data?.responseData?.items}
              totalCount={transactionsPayload?.data?.responseData?.count}
              currentPage={transactionsPayload?.data?.responseData?.pageNumber}
              pageSize={transactionsPayload?.data?.responseData?.pageSize}
              onPageChange={(page) =>
                setPage((prev) => ({ ...prev, pageNumber: page }))
              }
              totalPages={transactionsPayload?.data?.responseData?.pages}
            />
          </div>
        )}
      <WebhookNotification
        reference={reference}
        show={viewWebhook}
        toggleModal={() => setViewWebhook(!viewWebhook)}
      />

      <ViewModal
        data={selectedRow}
        toggle={() => {
          if (selectedRow) {
            setSelectedRow(null);
          }
        }}
        open={!!selectedRow}
      />
    </div>
  );
};

export default Transactions;
