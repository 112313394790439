import BackButton from "pages/NavigationButton/BackButton";
import React from "react";

const ProvidersHeader = ({ onClick }) => {
  return (
    <>
      <BackButton noPadding onClick={onClick} iconClassName="ri-2x" />
      <h1
        style={{
          fontSize: 20,
          fontWeight: 600,
        }}
      >
        Choose Provider Bank
      </h1>
    </>
  );
};

export default ProvidersHeader;
