import FilterDropdown from "shared/FilterDropdown/FilterDropdown";
import { LoadingTableContent } from "components/loader/Loader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _debounce from "lodash.debounce";

import { getAccountDetails, getAccounts } from "redux/actions/account.action";
import EmptyState from "shared/empty/empty";
import TablePagination from "shared/tablePagination/tablePagination";
import ViewModal from "./viewModal/ViewModal";
import Table from "vgg-ui-react/dist/components/Core/Table";
import AccountFormModal from "./accountFormModal/AccountFormModal"
import "./AccountsPage.scss";
import { Button } from "reactstrap";

const AccountsPage = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showAccountForm, setShowAccountForm] = useState(false);

  const { isGettingAccounts, getAccountsSuccess } = useSelector(({ account }) => {
    return {
      isGettingAccounts: account?.isGettingAccounts,
      getAccountsSuccess: account?.getAccountsSuccess,
    };
  });

  useEffect(() => {
    dispatch(getAccounts(currentPage, 10, searchValue));
  }, [dispatch, currentPage, searchValue]);

  const applyFilter = ({ _status, destination, source }) => {
    dispatch();
  };

  const toggleFilterDropdown = () => {
    showFilterDropdown ? setShowFilterDropdown(false) : setShowFilterDropdown(true);
  };

  const handleSearch = _debounce(keyword => {
    setSearchValue(keyword);
  }, 1000);

  return (
    <div className='accounts-page-container table-wrapper'>
      <div className='border-bottom p-3'>
        <h4 className='page-title'>Accounts</h4>
      </div>

      <div className='border-bottom p-3'>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <div className='search-container'>
            <input type='text' placeholder='search' className='form-control' data-testid='search-input' onChange={e => handleSearch(e.target.value)} />
            <i className='ri-search-line'> </i>
          </div>
          <div className='d-flex align-items-center'>
            <FilterDropdown dropdownOpen={showFilterDropdown} onSubmit={applyFilter} disabled={true} toggleDropdown={toggleFilterDropdown} />
            <Button
              color="primary"
              className="transfer-btn ms-3"
              onClick={() => setShowAccountForm(true)}
            >
              Create new account
            </Button>
          </div>
        </div>

        <Table cellVariants='default' className='table-striped'>
          <thead>
            <tr>
              <th>Provider</th>
              <th>Account Number</th>
              <th>Account Type</th>
              <th>Country</th>
              <th>Currency</th>
              <th>{""}</th>
            </tr>
          </thead>
          {isGettingAccounts ? (
            <tbody style={{ borderTop: "none" }}>
              <LoadingTableContent colSpan={6} />
            </tbody>
          ) : (
            <>
              {getAccountsSuccess?.responseData?.items?.length > 0 && (
                <tbody style={{ borderTop: "none" }}>
                  {getAccountsSuccess?.responseData?.items?.map(account => {
                    return (
                      <tr key={account.accountNo}>
                        <td>{account.providerName || "_"}</td>
                        <td>{account.accountNo || "_"}</td>
                        <td>{account.accountType || "_"}</td>
                        <td>{account.country || "_"}</td>
                        <td>{account.currency || "_"}</td>
                        <td>
                          <span onClick={() => {
                          setSelectedRow(account);
                          dispatch(getAccountDetails(account?.accountId))
                        }} className="view">View</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </>
          )}
        </Table>
      </div>
      {!isGettingAccounts && !getAccountsSuccess?.responseData?.items.length && <EmptyState />}
      {getAccountsSuccess?.responseData?.items?.length > 0 && (
        <div className='px-3'>
          <TablePagination
            data={getAccountsSuccess?.responseData?.items}
            totalCount={getAccountsSuccess?.responseData?.count}
            currentPage={getAccountsSuccess?.responseData?.pageNumber}
            pageSize={getAccountsSuccess?.responseData?.pageSize}
            onPageChange={page => {
              setCurrentPage(page);
            }}
            totalPages={getAccountsSuccess?.responseData?.pages}
          />
        </div>
      )}

     <ViewModal
        data={selectedRow}
        toggle={() => {
          if (selectedRow) {
            setSelectedRow(null);
          }
        }}
        open={!!selectedRow}
      />

    <AccountFormModal
              show={showAccountForm}
              toggleModal={() => setShowAccountForm(!showAccountForm)}
            />
    </div>
  );
};

export default AccountsPage;
