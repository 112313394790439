import { format } from "date-fns";
import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import "./viewChargebackModal.scss";

const Item = ({ label, value }) => (
  <div className="py-4 bb">
    <Typography typographyVariant="caption" text={label} className="me-3 mb-3 align-self-start" />
    <Typography typographyVariant="text1" text={value || "--"} className="me-3 align-self-start" />
  </div>
);

const ViewChargebackModal = ({ open, toggle, data }) => {
  return (
    <Modal backdrop="static" isOpen={open} toggle={toggle} className="drawer fadeInRight w-480 animated ml-auto">
      <ModalBody>
        <Item label="Transaction Reference" value={data?.transactionReference} />
        <Item label="Merchant Name" value={data?.merchantName} />
        <Item label="Chargeback Type" value={data?.chargeType} />
        <Item label="Date Created" value={data?.createdAt && format(new Date(data?.createdAt), "dd/MM/yyyy")} />
        <Item label="Reason for Chargeback" value={data?.reason} />
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export default ViewChargebackModal;
