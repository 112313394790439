import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";
import { CancelMark, CollopseArrow } from "shared/notifications/icons";
import "./viewModal.scss";

const Item = ({ label, value, loading }) => (
  <div className="view-items">
    <div className="view-title">{label}</div>
    <div className="view-value">{loading?<Spinner color="primary" size="sm"/>:value===0?0:value || "--"}</div>
    </div>
);

const ViewModal = ({ open, toggle, data }) => {
  const [viewSection, setViewSection] = useState({account: false, kyc: false})
  const { getAccountDetailSuccess, getAccountDetailLoading } = useSelector (({ account }) => {
    return {
      getAccountDetailSuccess: account?.getAccountDetailSuccess,
      getAccountDetailError: account?.getAccountDetailError,
      getAccountDetailLoading: account?.getAccountDetailLoading,
    
    };
  });

  console.log({getAccountDetailSuccess})
  return (
    <Modal backdrop="static" isOpen={open} toggle={toggle} className="view-account-form-container drawer fadeInRight w-480 animated ml-auto">
      <ModalBody>
        <div className="header">
          <div className="cancel" onClick={toggle}><CancelMark/></div>
        </div>
        <div className="title">{data?.accountName}</div>
        <div className="desc">Information about generated account.</div>
        <div className="details">
           <div className="detail">
             Account details
             <span onClick={()=>setViewSection({...viewSection, account: !viewSection?.account})}><CollopseArrow/></span>
             </div>
             {viewSection?.account && <><Item label="Provider" value={data?.providerName} />
        <Item label="Account Name" value={data?.accountName} />
        <Item label="Account Number" value={data?.accountNo} />
        <Item label="Account Type" value={data?.accountType} />
        <Item label="Country" value={data?.country} />
        <Item label="Currency" value={data?.currency} />
        <Item label="Ledger Balance" value={getAccountDetailSuccess?.responseData?.ledgerBalance} loading={getAccountDetailLoading}/>
        <Item label="Account Balance" value={getAccountDetailSuccess?.responseData?.accountBalance} loading={getAccountDetailLoading}/>
        </>}
      </div>
        <div className="details">
           <div className="detail">
            KYC details
             <span onClick={()=>setViewSection({...viewSection, kyc: !viewSection?.kyc})}><CollopseArrow/></span>
             </div>
             {viewSection?.kyc && <><Item label="KYC Type" value={data?.bvnType} />
        <Item label="Phone Number" value={data?.accountNo} />
        <Item label="First Name" value={data?.firstName} />
        <Item label="Last Name" value={data?.lastName} />
        <Item label="D.O.B" value={data?.dob} />
        </>}
      </div>
      </ModalBody>
       <ModalFooter></ModalFooter>
    </Modal>
  );
};

export default ViewModal;
