import React, { useRef, useState } from "react";
import Button from "vgg-ui-react/dist/components/Core/Button";
import { ReactComponent as InfoIcon } from "../../assets/images/infoLarge.svg";
import { useDispatch } from "react-redux";
import "./awaitingApprovalPage.scss";
import { successMessage } from "redux/actions/notification";
import OnboardProvidersModal from "components/OnboardProvidersModal/OnboardProvidersModal";
import SuccessModal from "components/SuccessModal/SuccessModal";
import { checkMerchantStatus, fetchAllProviders } from "redux/actions/account.action";
import BackButton from "pages/NavigationButton/BackButton";

const AwaitingApprovalPage = ({ description = "Your onboarding request has been successfully submitted and feedback will be provided shortly.", clientIdentifier }) => {
  const itemRef = useRef(null);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [statusContent, setStatusContent] = useState(null);

  const onOnboardClick = () => {
    setOpen(true);
  };

  console.log(itemRef.current);

  return (
    <div className='awaiting-onboard-wrapper'>
      <BackButton label='Go to Products' remixCode='close-line' />
      <div className='d-flex flex-column align-items-center justify-content-center content-wrapper' ref={itemRef}>
        <div className='mb-3'>
          <InfoIcon />
        </div>
        <div className='mb-3'>
          <h3 className='success-title'>Onboard Pending!</h3>
        </div>
        <p className='mb-3 success-content'>{description}</p>
        {
          <p className='mb-3 success-content'>
            {/* {onboardedCurrencies?.responseData.map((v) => (
              <strong className="mr-1" key={v.currency}>
                {v.currency}
              </strong>
            ))} */}
            {/* Providers Awaiting Approval */}
          </p>
        }

        <Button label='Onboard New Provider' background='blue' onClick={onOnboardClick} />
      </div>
      {open && (
        <OnboardProvidersModal
          open={open}
          toggle={() => setOpen(false)}
          onSuccess={data => {
            console.log("on awitin", data);
            setStatusContent(data);
            setOpen(false);
            dispatch(successMessage("Onboard successful"));
          }}
          onClosed={() => setOpen(false)}
        />
      )}

      <SuccessModal
        awaitingPage
        open={statusContent?.fieldChildren || statusContent?.successChildren}
        statusText={statusContent}
        failed={statusContent?.failed}
        onClose={() => {
          if (statusContent?.failed && statusContent?.succeed) {
            setStatusContent(prev => ({ ...prev, fai: [] }));
          } else {
            setOpen(false);
            setStatusContent(null);
            dispatch(fetchAllProviders(clientIdentifier));
            dispatch(checkMerchantStatus());
          }
        }}
      >
        {statusContent?.failedChildren || statusContent?.successChildren}
      </SuccessModal>
    </div>
  );
};

export default AwaitingApprovalPage;
