import OnboardProvidersModal from "components/OnboardProvidersModal/OnboardProvidersModal";
import SuccessModal from "components/SuccessModal/SuccessModal";
import BackButton from "pages/NavigationButton/BackButton";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  checkMerchantStatus,
  fetchAllProviders,
} from "redux/actions/account.action";
import Button from "vgg-ui-react/dist/components/Core/Button";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import "./onboardingPage.scss";

function OnboardingPage({ clientIdentifier }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [statusContent, setStatusContent] = useState(null);

  return (
    <div className="onboard-wrapper">
      <BackButton label="Virtual Account" iconClassName="ri-2x" />
      <section className="card-section sp-pt--24 sp-pb--24 sp-pl--32 sp-pr--32">
        <div className="sp-mb--16">
          <Typography
            typographyVariant="section-title"
            text="Create virtual accounts in minutes."
          />
        </div>
        <div className="w-sm-100 w-75">
          <p className="content-paragraph">
            BaseOne empowers you to create unlimited virtual accounts for your
            users and customers. Leverage the power of BaseOne to start creating
            virtual account numbers on a provider bank of your choice. Get
            started by choosing a provider bank.
          </p>
        </div>
        <div>
          <Button
            label="Onboard"
            background="blue"
            size="md"
            style={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "16px",
              letterSpacing: "0.4px",
            }}
            onClick={() => setOpen(true)}
          />
          <Button
            label={
              <span className="d-flex align-items-center">
                <span>Learn More</span>
                <i className="ri-arrow-right-up-line"></i>
              </span>
            }
            onClick={() =>
              window.open(
                "https://developer.baseone.co/docs/virtualaccounts/",
                "_blank"
              )
            }
            background="neutral"
            size="md"
            style={{
              background: "#fff",
              border: "1px solid #D7DCE0",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "16px",
              letterSpacing: "0.4px",
              color: "#505D67",
            }}
          />
        </div>
        <p className="footnote">
          Note: BaseOne empowers you to create unlimited virtual accounts for
          your users and customers.
        </p>
        {open && (
          <OnboardProvidersModal
            open={open}
            toggle={() => setOpen(false)}
            onSuccess={(data) => {
              // setStatusContent(data);
              setOpen(false);
              setStatusContent(null);
              dispatch(fetchAllProviders(clientIdentifier));
            }}
            newMerchant
            onClosed={() => setOpen(false)}
          />
        )}
        <SuccessModal
          open={statusContent?.fieldChildren || statusContent?.successChildren}
          statusText={statusContent}
          failed={statusContent?.failed}
          onClose={() => {
            if (statusContent?.failed && statusContent?.succeed) {
              setStatusContent((prev) => ({ ...prev, fai: [] }));
            } else {
              setOpen(false);
              setStatusContent(null);
              dispatch(fetchAllProviders(clientIdentifier));
              dispatch(checkMerchantStatus());
            }
          }}
        >
          {statusContent?.failedChildren || statusContent?.successChildren}
        </SuccessModal>
      </section>
    </div>
  );
}

export default OnboardingPage;
