export const URLS = {
  Home: "/",
  Manage: "/products/virtual-account/manage",
  ProviderAccount: "/products/virtual-account/account/:id",
  Accounts: "/products/virtual-account/service/accounts",
  Overview: "/products/virtual-account/service/overview",
  Transactions: "/products/virtual-account/service/transactions",
  Transfers: "/products/virtual-account/service/transfers",
  Settings: "/products/virtual-account/service/settings",
  ChargeBack: "/products/virtual-account/service/chargeback",
  Settlements: "/products/virtual-account/service/settlements",
  About: "/products/virtual-account/service/about",
  Onboard: "/products/virtual-account/welcome",
  SettlementTransactions: "/products/virtual-account/settlement/transactions",
};

export const ProviderAccountRoute = (id) => {
  return URLS.ProviderAccount.replace(":id", id);
};

export default URLS;
