import CustomTab from "components/CustomTab/CustomTab";
import { AppLoader } from "components/loader/Loader";
import { format } from "date-fns";
import { getCurrencyValue } from "helpers/currencyMarkup";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Card } from "reactstrap";
import EmptyState from "shared/empty/empty";
import Typography from "vgg-ui-react/dist/components/Core/Typography";

const tabItems = [
  { key: "NGN", title: "NGN", id: 1, content: null, disabled: false },
  { key: "GHC", title: "GHC", id: 2, content: null, disabled: true },
  { key: "KEN", title: "KEN", id: 3, content: null, disabled: true },
];

export const options = {
  responsive: true,
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat("en-ng", {
              style: "currency",
              currency: "NGN",
              currencyDisplay: "symbol",
            }).format(context.parsed.y);
          }
          return label;
        },
      },
    },
    legend: {
      display: false,
      align: "start",
    },
    title: {
      display: false,
      text: "",
      align: "start",
    },
  },
};

const BarChartOverview = ({ loading, dataSource, totalReceived, onCurrencyTabClick }) => {
  const [data, setData] = useState(null);

  const handleTabItemClick = data => {
    onCurrencyTabClick && onCurrencyTabClick(data);
  };

  useEffect(() => {
    if (dataSource && dataSource.length) {
      const d = dataSource.map(v => v.creditAmount);
      setData({
        labels: dataSource.map(v => v.date && format(new Date(v.date), "dd MMM")),
        datasets: [
          {
            label: "",
            data: d,
            hoverBackgroundColor: "#175FFF",
            backgroundColor: "rgba(23, 95, 255, 0.2)",
            maxBarThickness: 25,
          },
        ],
      });
    } else {
      setData(null);
    }
  }, [dataSource]);

  return (
    <Card>
      <div className="sp-p--24 sp-mb--16 w-100 d-flex align-items-center justify-content-between bb-100">
        <Typography typographyVariant="subtitle2" text="Total Amount Received" className="font-weight-500" />
        <div>
          <CustomTab useContent={false} data={tabItems} onTabItemClick={handleTabItemClick} />
        </div>
      </div>
      <div className="sp-p--24 sp-mb--16">
        <Typography typographyVariant="disp-heading" text={getCurrencyValue("", totalReceived)} className="font-weight-500" />
      </div>
      {loading ? (
        <AppLoader height="50vh" width="100%" />
      ) : (
        <>
          {data ? (
            <Bar options={options} data={data} height="80vh" />
          ) : (
            <div className="d-flex align-items-center justify-content-center w-100 vh-40">
              <EmptyState showMessage={false} />
            </div>
          )}
        </>
      )}
    </Card>
  );
};

export default BarChartOverview;
