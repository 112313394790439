import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorPage from "components/ErrorPage/ErrorPage";
import OnboardingPage from "pages/OnboardingPage/OnboardingPage";
import ServiceDetails from "pages/ServiceDetails/ServiceDetails";
import { checkMerchantStatus } from "redux/actions/account.action";
import CustomSpinner from "components/CustomSpinner/CustomSpinner";
import { getUserDetails } from "apiServices/user.service";
import jwt_decode from "jwt-decode";
import AwaitingApprovalPage from "pages/AwaitingApprovalPage/AwaitingApprovalPage";

const Manager = () => {
  const dispatch = useDispatch();
  const { Client_Identifier } = getUserDetails()?.accessToken
    ? jwt_decode(getUserDetails()?.accessToken)
    : {};

  const { checkingMerchantStatus, merchantStatus } = useSelector(
    ({ account }) => account
  );

  useEffect(() => {
    dispatch(checkMerchantStatus());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderConditionalContent = (onboarded, active) => {
    let status;

    if (onboarded) {
      if (active) {
        status = <ServiceDetails />;
      } else {
        status = <AwaitingApprovalPage clientIdentifier={Client_Identifier} />;
      }
    } else {
      status = <OnboardingPage clientIdentifier={Client_Identifier} />;
    }

    return status;
  };

  if (checkingMerchantStatus) {
    return <CustomSpinner height="75vh" />;
  }

  if (merchantStatus?.data && merchantStatus?.data.requestSuccessful) {
    return renderConditionalContent(
      merchantStatus?.data?.responseData?.isOnboarded,
      merchantStatus?.data?.responseData?.isOnboardedAndActive
    );
  }

  if (merchantStatus?.data && !merchantStatus?.data.requestSuccessful) {
    return <ErrorPage />;
  }
  return null;
};

export default Manager;
