import CustomSpinner from "components/CustomSpinner/CustomSpinner";
import React, { useEffect, useState } from "react";
import Lozenge from "vgg-ui-react/dist/components/Core/Lozenge";
import "./selectProviders.scss";

const SelectProviders = ({
  onCardClick,
  providers,
  onSearch,
  searchValue,
  onCheckboxChange,
  onboarding,
  onOnboardClick,
  selectedProvider,
}) => {
  const [loading, setLoading] = useState(true);
  const getStatusText = (onboarded, active, name) => {
    let status;

    if (onboarded) {
      if (active) {
        status = {
          content: "Onboarded",
          variant: "success",
        };
      } else {
        status = {
          content: "Pending",
          variant: "pending",
        };
      }
    } else {
      status = {
        content: name === "Bishop Gate" ? "Unavailable" : "Available",
        variant: "default",
      };
    }

    return status;
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);
  return (
    <div className="col-12">
      <div className="wrap-search bb">
        <input
          className="form-control full-width search-input"
          placeholder="Search providers"
          onChange={onSearch}
        />
      </div>
      <div className="providers-main">
        {loading ? (
          <CustomSpinner height="50vh" />
        ) : (
          <>
            <p className="providers-main__instruction fade_in">
              Choose any country to continue..
            </p>
            <div className="providers-main__list px-3 fade_in">
              <div className="row">
                {providers && providers.length > 0 ? (
                  <>
                    {providers.map((provider) => (
                      <div key={provider.id} className="col-6 mb-3">
                        <div className="provider-item">
                          <input
                            type="checkbox"
                            id={`rowcheck${provider?.id}`}
                            name={`rowcheck${provider?.id}`}
                            checked={
                              provider.id === selectedProvider?.id &&
                              selectedProvider?.selected
                            }
                            disabled={
                              (provider?.isMerchantOnboarded &&
                                !provider?.isActive) ||
                              provider.name === "Bishop Gate"
                            }
                            onChange={(e) =>
                              !provider?.isMerchantOnboarded &&
                              onCheckboxChange &&
                              onCheckboxChange(e, provider)
                            }
                          />
                          <label
                            htmlFor={`rowcheck${provider?.id}`}
                            className={
                              provider?.isMerchantOnboarded &&
                              provider?.isActive
                                ? "active"
                                : ""
                            }>
                            {provider.name} <br />
                            <Lozenge
                              content={
                                getStatusText(
                                  provider?.isMerchantOnboarded,
                                  provider?.isActive,
                                  provider?.name
                                )?.content
                              }
                              variant={
                                getStatusText(
                                  provider?.isMerchantOnboarded,
                                  provider?.isActive,
                                  provider?.name
                                )?.variant
                              }
                            />
                          </label>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="d-flex align-items-center justify-content-center flex-wrap">
                    {searchValue ? (
                      <span>"{`${searchValue}`}" not found</span>
                    ) : (
                      <span>No Data</span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SelectProviders;
