import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import "./errorPage.scss";

const ErrorPage = ({ message }) => {
  const history = useHistory();
  return (
    <div id="center pageMessages">
      <div className="center alert animated flipInX alert-danger alert-dismissible">
        <h4>
          <i className="fa ffa fa-exclamation-circle"></i>Opps!
        </h4>
        <strong>Something went wrong</strong>
        <p>{message || "Sorry we could not detect your status on this service."}</p>
        <span className="close" data-dismiss="alert">
          <i className="fa fa-times-circle"></i>
        </span>
        <Button className="btn btn-danger" onClick={() => history.push("/products")}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;
