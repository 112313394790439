import "./TransferModal.scss";
import React, { useEffect, useState } from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { SpinLoader } from "components/loader/Loader";

import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCollectionWallets,
  getBanks,
  getStaticAccounts,
  interTransfer,
  resolveBankAccount,
} from "redux/actions/account.action";
import { errorMessage, successMessage } from "redux/actions/notification";
import Notification from "shared/notifications/notifications";

const TransferModal = ({ show, toggleModal }) => {
  const [submitStatus, setSubmitStatus] = useState(null);
  const [form, setForm] = useState([]);
  const [validForm, setValidForm] = useState(false);
  const dispatch = useDispatch();
  const [institutionCode, setInstitutionCode] = useState(null);
  const [accNumber, setAccNumber] = useState(null);
  const [loadAcctName, setLoadAcctName] = useState(false);
  const [remoteValues, setRemoteValues] = useState({
    wallet: "",
    acctName: "",
  });

  const {
    isGettingBanks,
    getBanksSuccess,
    fetchingCollectionWallets,
    conllectionWalletPayload,
    interTransferLoading,
    interTransferSuccess,
    interTransferError,
    resolveBankAccountLoading,
    resolveBankAccountSuccess,
    resolveBankAccountError
  } = useSelector(({ account }) => {
    return {
      isGettingBanks: account.isGettingBanks,
      getBanksSuccess: account.getBanksSuccess,
      isGettingStaticAccounts: account.isGettingStaticAccounts,
      getStaticAccountsSuccess: account.getStaticAccountsSuccess,
      fetchingCollectionWallets: account.fetchingCollectionWallets,
      conllectionWalletPayload: account.conllectionWalletPayload,
      interTransferLoading: account.interTransferLoading,
      interTransferError: account.interTransferError,
      interTransferSuccess: account.interTransferSuccess,
      resolveBankAccountLoading: account.resolveBankAccountLoading,
      resolveBankAccountSuccess: account.resolveBankAccountSuccess,
      resolveBankAccountError: account.resolveBankAccountError,
    };
  });

  useEffect(() => {
    if (validForm) {
      setLoadAcctName(true)
      dispatch(resolveBankAccount(accNumber, institutionCode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validForm]);

  useEffect(() => {
    if (validForm) {
      dispatch(resolveBankAccount(accNumber, institutionCode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validForm]);

  useEffect(() => {
    dispatch(getBanks());
    dispatch(getStaticAccounts(1, 200, false));
    dispatch(fetchCollectionWallets(1, 200, false));
  }, [dispatch]);

  useEffect(() => {
    if (submitStatus && !isGettingBanks && !getBanksSuccess.requestSuccesful) {
      dispatch(
        errorMessage(
          getBanksSuccess.message || "An error occured when getting Banks"
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBanksSuccess]);

  useEffect(() => {
    if (!interTransferLoading && submitStatus) {
      if (interTransferSuccess?.requestSuccessful) {
        toggleModal();
        dispatch(
          successMessage(interTransferSuccess?.message || "Transfer Successful")
        );
      } else {
        // setFeedBack({error: true, message: interTransferSuccess?.message||"Error occurred while performing this action"})
        dispatch(
          errorMessage(interTransferSuccess?.message || "Transfer Failed")
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interTransferSuccess]);

  useEffect(() => {
    if (!interTransferLoading && submitStatus && !interTransferError) {
      dispatch(errorMessage(interTransferError?.message || "Transfer Failed"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interTransferError]);

  useEffect(() => {
    if (show) {
      if (
        !resolveBankAccountLoading &&
        accNumber &&
        institutionCode &&
        resolveBankAccountSuccess?.requestSuccessful && loadAcctName
      ) {
        setLoadAcctName(false);
        setRemoteValues((prev) => ({
          ...prev,
          acctName: resolveBankAccountSuccess?.responseData,
        }));
      } else if((loadAcctName && ((resolveBankAccountSuccess && !resolveBankAccountLoading && !resolveBankAccountSuccess?.requestSuccessful) || resolveBankAccountError))) {
        setLoadAcctName(false);
        setRemoteValues((prev) => ({ ...prev, acctName: "" }));
        dispatch(errorMessage("Error occurred trying to retrieve the account name, please try again"))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolveBankAccountSuccess, resolveBankAccountLoading, show]);

  const checkForm = () => {
    const obj = form.state.invalidInputs;
    const emptyValidation =
      obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    setValidForm(emptyValidation);
  };

  const getBankName = (code) => {
    return getBanksSuccess.responseData.find((bank) => bank.bankCode === code)
      ?.bankName;
  };

  const submit = (e, value) => {
    setSubmitStatus(true);
    const payload = {
      mifosAccountId: value.wallet,
      destinationAccount: value.accountNumber,
      destinationBankCode: value.bank,
      destinationAccountName: remoteValues.acctName,
      destinationBankName: getBankName(value.bank),
      amount: value.amount,
      narration: "",
      clientReference: "",
    };
    dispatch(interTransfer(payload));
  };

  useEffect(() => {
    if (!show) {
      setRemoteValues({ acctName: "", wallet: "" });
      setAccNumber("");
      setInstitutionCode("");
    }
  }, [show]);

  
  return (
    <div className="transfer-modal-container">
      <Modal
        centered={true}
        fade={false}
        toggle={toggleModal}
        isOpen={show}
        backdrop="static"
      >
        <ModalHeader className="border-bottom-0" toggle={toggleModal}>
          Make Transfer
        </ModalHeader>
      <Notification />
        <ModalBody>
          <AvForm
            data-testid="transferForm"
            onValidSubmit={submit}
            ref={(c) => setForm(c)}
            onKeyUp={checkForm}
          >
            <div className="md-3">
              <label className="form-label" htmlFor="wallet">
                Choose wallet
              </label>
              <AvField
                type="select"
                name="wallet"
                onChange={(e) => {
                  const value = e.target.value;
                  setRemoteValues((prev) => ({ ...prev, wallet: value }));
                }}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Your Wallet is required",
                  },
                }}
              >
                <option disabled selected value="">
                  {fetchingCollectionWallets
                    ? "loading..."
                    : "please select a wallet"}
                </option>
                {conllectionWalletPayload?.responseData?.map((account) => (
                  <option
                    className="text-capitalize"
                    key={account.id}
                    value={account.accountId}
                  >
                    {account.accountName ||
                      `Account Name - ${account.accountBalance}`}
                  </option>
                ))}
              </AvField>
              {remoteValues.wallet && (
                <div className="d-flex align-items-center justify-content-end f-12">
                  <div>Account Balance:</div>
                  <div className="font-weight-bold">
                    {
                      conllectionWalletPayload?.responseData.find(
                        (v) => v.accountId === remoteValues.wallet
                      )?.accountBalance || 0
                    }
                  </div>
                </div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="amount">
                Enter Amount to send
              </label>
              <AvField
                autoComplete="off"
                type="text"
                name="amount"
                placeholder="Amount"
                validate={{
                  number: true,
                  required: {
                    value: true,
                    errorMessage: "Your amount is required",
                  },
                  maxLength: {
                    value: 255,
                    errorMessage:
                      "Your amount must not be more than 255 characters",
                  },
                  minLength: {
                    value: 1,
                    errorMessage:
                      "Your amount must contain at least 1 character",
                  },
                  min: {
                    value: 1,
                    errorMessage: "Your amount can not be below 1",
                  },
                }}
              />
            </div>
            <div className="md-3">
              <label className="form-label" htmlFor="bank">
                Choose Bank
              </label>
              <AvField
                type="select"
                name="bank"
                onChange={(e) => setInstitutionCode(e.target.value)}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Bank is required",
                  },
                }}
              >
                <option disabled selected value="">
                  {isGettingBanks ? "Loading..." : " please select a Bank"}
                </option>
                {getBanksSuccess?.responseData?.map((bank) => (
                  <option
                    className="text-capitalize"
                    key={bank.bankCode}
                    value={bank.bankCode}
                  >
                    {bank.bankName}
                  </option>
                ))}
              </AvField>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="accountNumber">
                Account Number
              </label>
              <AvField
                autoComplete="off"
                type="text"
                onChange={(e) => setAccNumber(e.target.value)}
                name="accountNumber"
                placeholder="0123456789"
                validate={{
                  number: true,
                  required: {
                    value: true,
                    errorMessage: "Account number is required",
                  },
                  maxLength: {
                    value: 12,
                    errorMessage:
                      "Account number must not be more than 12 characters",
                  },
                  minLength: {
                    value: 10,
                    errorMessage:
                      "Account number must contain at least 10 characters",
                  },
                }}
              />
              {resolveBankAccountLoading && (
                <p className="text-sm">Fetching Account...</p>
              )}
              {!resolveBankAccountLoading &&
              remoteValues.acctName &&
              accNumber &&
              institutionCode ? (
                <p className="fw-bold text-sm">{remoteValues.acctName}</p>
              ) : undefined}
            </div>
            <Button
              type="submit"
              color="primary"
              className="submit-btn w-100"
              disabled={!validForm || interTransferLoading || !remoteValues.acctName || resolveBankAccountLoading}
              handleClick={() => form.submit()}
            >
              Make Transfer{" "}
              {interTransferLoading && <SpinLoader size="sm" color="light" />}
            </Button>
          </AvForm>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default TransferModal;
