import React from "react";
import "./service-details.scss";
import CustomTab from "components/CustomTab/CustomTab";
import URLS from "helpers/urls";
import Overview from "pages/Overview/Overview";
import Transactions from "pages/Transactions/Transactions";
import Transfers from "pages/Transfers/Transfers";
import Settings from "pages/Settings/Settings";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import AccountsPage from "pages/AccountsPage/AccountsPage";
import About from "pages/About/About";
import BackButton from "pages/NavigationButton/BackButton";
import ChargeBack from "pages/ChargeBack/ChargeBack";
import Settlements from "pages/Settlements/Settlements";

const tabItems = [
  {
    id: 1,
    title: "Overview",
    key: "overview",
    routeLink: URLS.Overview,
    component: Overview,
  },
  {
    id: 2,
    title: "Accounts",
    key: "accounts",
    routeLink: URLS.Accounts,
    component: AccountsPage,
  },
  {
    id: 3,
    title: "Transactions",
    key: "transactions",
    routeLink: URLS.Transactions,
    component: Transactions,
  },
  {
    id: 4,
    title: "Transfer",
    key: "transfer",
    routeLink: URLS.Transfers,
    component: Transfers,
  },
  {
    id: 5,
    title: "Chargeback",
    key: "chargback",
    routeLink: URLS.ChargeBack,
    component: ChargeBack,
  },
  {
    id: 6,
    title: "Settlements",
    key: "settlements",
    routeLink: URLS.Settlements,
    component: Settlements,
  },
  {
    id: 7,
    title: "Settings",
    key: "settings",
    routeLink: URLS.Settings,
    component: Settings,
  },
  {
    id: 8,
    title: "About",
    key: "about",
    routeLink: URLS.About,
    component: About,
  },
];

const TabContents = () => {
  const history = useHistory();
  return (
    <div>
      <BackButton label="Virtual Account" iconClassName="ri-2x" />

      <section
        className="content-section sp-pt--24"
        data-testid="content-section"
      >
        <CustomTab
          data={tabItems}
          defaultActiveTab={
            tabItems.find((v) => v.routeLink === history?.location?.pathname)
              ?.key
          }
        >
          <Switch>
            {tabItems.map((item) => (
              <Route
                key={item.key}
                exact
                path={item.routeLink}
                component={item.component}
              />
            ))}
          </Switch>
        </CustomTab>
      </section>
    </div>
  );
};

export default TabContents;
