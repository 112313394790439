import { userDetails } from "./user.service";

export const virtualAccountBaseUrl =
  process.env.NODE_ENV === "development"
    ? "https://virtualaccount-apitest.azurewebsites.net/api"
    : `${window.env?.virtualAccountBaseUrl}/api`;

export const virtualAccountBaseUrlWithoutApi =
  process.env.NODE_ENV === "development"
    ? "https://virtualaccount-apitest.azurewebsites.net"
    : `${window.env?.virtualAccountBaseUrl}`;

export const serviceManagerAdminBaseUrl =
  process.env.NODE_ENV === "development"
    ? "https://afservicemanagertest.azurewebsites.net/api"
    : `${window.env?.serviceManagerBaseUrl}/api`;

export const serviceManagerBaseUrl =
  process.env.NODE_ENV === "development"
    ? "https://afservicemanagerclienttest.azurewebsites.net/api"
    : `${window.env?.serviceManagerBaseUrl}/api`;

export const config = () => {
  if (userDetails()) {
    const bearerToken = `Bearer ${userDetails().accessToken}`;
    return { headers: { Authorization: bearerToken } };
  }
  return null;
};
