import { getCurrencyValue } from "helpers/currencyMarkup";
import React from "react";
import "./topCards.scss";

const TopCards = ({ data }) => {
  return (
    <div className="row align-items-stretch">
      <div className="c-dashboardInfo col-lg-3 col-md-6">
        <div className="wrap">
          <h4 className="d-flex align-items-center heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
            <span>Collection Available Balance</span>
          </h4>
          <span className="hind-font caption-12 c-dashboardInfo__count">{getCurrencyValue("", data?.collectionAvailableBalance)}</span>
        </div>
      </div>
      
      <div className="c-dashboardInfo col-lg-3 col-md-6">
        <div className="wrap">
          <h4 className="d-flex align-items-center heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
            <span>Collection Ledger Balance</span>
          </h4>
          <span className="hind-font caption-12 c-dashboardInfo__count">{getCurrencyValue("", data?.collectionLedgerBalance)}</span>
        </div>
      </div>

      <div className="c-dashboardInfo col-lg-3 col-md-6">
        <div className="wrap">
          <h4 className="d-flex align-items-center heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
            <span>Total Static Accounts Balance</span>
          </h4>
          <span className="hind-font caption-12 c-dashboardInfo__count">{getCurrencyValue("", data?.totalStaticAccountsBalance)}</span>
        </div>
      </div>

      <div className="c-dashboardInfo col-lg-3 col-md-6">
        <div className="wrap">
          <h4 className="d-flex align-items-center heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
            <span>Total Balance</span>
          </h4>
          <span className="hind-font caption-12 c-dashboardInfo__count">{getCurrencyValue("", data?.totalBalance)}</span>
        </div>
      </div>

    </div>
  );
};

export default TopCards;
