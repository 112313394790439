import React, { useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";

import Button from "vgg-ui-react/dist/components/Core/Button";
import Select from "react-select";
import * as Yup from "yup";
import { Controller, get, useForm } from "react-hook-form";
import { useYupValidationResolver } from "helpers/yupValidationResolver";
import SelectControl from "components/SelectControl/SelectControl";
import { useDispatch, useSelector } from "react-redux";
import { resolveBankAccount } from "redux/actions/account.action";

const BankDetailsModal = ({
  open,
  onClose,
  onSubmit,
  banks,
  loading,
  errorMessage,
  toggle,
}) => {
  const validationSchema = Yup.object({
    bankCode: Yup.string().required("Please choose a bank from the list"),
    accountNumber: Yup.string().required("Account number is required"),
    accountName: Yup.string().required("Account name is required"),
    country: Yup.string().required("Country is required"),
  });

  const mockCountries = [{ label: "Nigeria", value: "NGA", active: true }];

  const { resolveBankAccountLoading, resolveBankAccountSuccess } = useSelector(
    ({ account }) => {
      return {
        resolveBankAccountLoading: account.resolveBankAccountLoading,
        resolveBankAccountSuccess: account.resolveBankAccountSuccess,
        resolveBankAccountError: account.resolveBankAccountError,
      };
    }
  );

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({ resolver: useYupValidationResolver(validationSchema) });

  const dispatch = useDispatch();
  const accountNumber = watch("accountNumber");

  useEffect(() => {
    if (accountNumber && accountNumber.length === 10) {
      dispatch(
        resolveBankAccount(
          accountNumber,
          getValues("bankCode"),
          getValues("country")
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumber]);

  useEffect(() => {
    if (
      !resolveBankAccountLoading &&
      resolveBankAccountSuccess?.requestSuccessful
    ) {
      setValue("accountName", resolveBankAccountSuccess.responseData);
    } else if (
      !resolveBankAccountLoading &&
      getValues("accountNumber") &&
      !resolveBankAccountSuccess?.message
    ) {
      dispatch(errorMessage(resolveBankAccountSuccess.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolveBankAccountLoading]);

  return (
    <Modal
      isOpen={open}
      backdrop="static"
      onClosed={() => {
        onClose();
        reset();
      }}
      centered>
      <ModalHeader className="no-bb" toggle={toggle}>
        <div className="md-title">Settle to Bank Account</div>
      </ModalHeader>
      <ModalBody className="px-2">
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12 mb-3">
              <div className="form-group">
                <label htmlFor="bankCode">Country</label>
                <Controller
                  control={control}
                  name="country"
                  id="country"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      {...register("country", { required: true })}
                      onBlur={onBlur}
                      isDisabled={loading}
                      onChange={(e) => onChange({ target: e })}
                      inputRef={ref}
                      controlClassName={`form-control ${
                        errors?.bankCode ? "is-invalid" : ""
                      } ps-0 pt-0 pb-0`}
                      controlErrorMsg={errors?.country?.message}
                      components={{
                        IndicatorSeparator: () => null,
                        Control: SelectControl,
                      }}
                      defaultValue={value}
                      options={mockCountries}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-12 mb-3">
              <div className="form-group">
                <label htmlFor="bankCode">Bank Name</label>
                <Controller
                  control={control}
                  name="bankCode"
                  id="bankCode"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      {...register("bankCode", { required: true })}
                      onBlur={onBlur}
                      isDisabled={loading}
                      onChange={(e) => onChange({ target: e })}
                      inputRef={ref}
                      controlClassName={`form-control ${
                        errors?.bankCode ? "is-invalid" : ""
                      } ps-0 pt-0 pb-0`}
                      controlErrorMsg={errors?.bankCode?.message}
                      components={{
                        IndicatorSeparator: () => null,
                        Control: SelectControl,
                      }}
                      defaultValue={value}
                      options={banks}
                    />
                  )}
                />
                {loading && <h6 className="mt-2">Fetching Banks...</h6>}
              </div>
            </div>
            <div className="col-12 mb-3">
              <div className="form-group">
                <label htmlFor="accountNumber" className="form-label">
                  Bank Account Number
                </label>
                <input
                  type="text"
                  placeholder="BankAccount Number"
                  className={`form-control ${
                    errors?.accountNumber ? "is-invalid" : ""
                  }`}
                  id="accountNumber"
                  name="accountNumber"
                  {...register("accountNumber", { required: true })}
                />
                <div className="invalid-feedback">
                  {errors?.accountNumber?.message}
                </div>
                {resolveBankAccountLoading && (
                  <h6 className="mt-2">Resolving Account...</h6>
                )}
              </div>
            </div>
            {resolveBankAccountSuccess?.responseData && (
              <div className="col-12 mb-3">
                <div className="form-group">
                  <label htmlFor="accountName" className="form-label">
                    Bank Account Name
                  </label>
                  <input
                    type="text"
                    readOnly
                    placeholder="Bank Account Name"
                    className={`form-control ${
                      errors?.accountName ? "is-invalid" : ""
                    }`}
                    id="accountName"
                    name="accountName"
                    {...register("accountName", { required: true })}
                  />
                  <div className="invalid-feedback">
                    {errors?.accountName?.message}
                  </div>
                </div>
              </div>
            )}
            <div className="col-12">
              <Button
                label={
                  loading ? (
                    <Spinner color="primary" type="grow">
                      Loading...
                    </Spinner>
                  ) : (
                    "Continue"
                  )
                }
                disabled={
                  resolveBankAccountLoading ||
                  !resolveBankAccountSuccess?.requestSuccessful
                }
                background="blue"
                size="md"
                className="action-btn"
                type="submit"
              />
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default BankDetailsModal;
