import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import "./customTab.scss";

const CustomTab = ({
  children,
  data,
  defaultActiveTab,
  onTabItemClick,
  useContent = true,
}) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab || data[0]?.key);

  const handleLinkClick = (event, item) => {
    onTabItemClick && onTabItemClick(item);
    setActiveTab(item.key);
  };

  return (
    <>
      <Nav className="mb-3">
        {data?.map((item) => (
          <NavItem key={item.key}>
            {item?.routeLink ? (
              <Link
                className={`nav-link sp-navlink ${item.key === activeTab ? "active" : ""
                  }`}
                to={item.routeLink}
                onClick={(e) => handleLinkClick(e, item)}
              >
                {item?.title}
              </Link>
            ) : (
              <NavLink
                className={`sp-navlink ${item.disabled ? "disabled" : ""}`}
                active={item.key === activeTab}
                disabled={item.disabled}
                onClick={(e) => !item.disabled && handleLinkClick(e, item)}
              >
                <>{item?.title}</>
              </NavLink>
            )}
          </NavItem>
        ))}
      </Nav>

      {useContent && (
        <TabContent activeTab={activeTab}>
          {children ? (
            <>{children}</>
          ) : (
            <>
              {data?.map((item) => (
                <TabPane key={item.key} tabId={item.key}>
                  <Row>
                    <Col sm="12">{item?.content}</Col>
                  </Row>
                </TabPane>
              ))}
            </>
          )}
        </TabContent>
      )}
    </>
  );
};

export default CustomTab;
