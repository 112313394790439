import React from "react";
import { components } from "react-select";

const SelectControl = props => (
  <>
    <components.Control className={props?.selectProps?.controlClassName} {...props} data-testid="selectinput">
      {props.children}
    </components.Control>
    <div className="invalid-feedback">{props?.selectProps?.controlErrorMsg}</div>
  </>
);

export default SelectControl;
