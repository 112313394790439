import React from "react"
import { SuccessMark } from "shared/notifications/icons";
import { Button } from "reactstrap";

const SuccessMessage = ({closeModal}) => {
    return (<>
    <div className="success-items">
       <SuccessMark/>
       <div className="success-text">Success!!</div>
       <div className="success-msg">Account was created successfully</div>
     </div>
     <Button
        color="primary"
        className="submit-btn"
        onClick={closeModal}
      >
        Done
      </Button>
     </>)
}

export default SuccessMessage