import FilterDropdown from "shared/FilterDropdown/FilterDropdown";
import { LoadingTableContent } from "components/loader/Loader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";
import { format } from "date-fns";
import { NumericFormat } from "react-number-format";
import _debounce from "lodash.debounce";

import { fetchSettlements } from "redux/actions/account.action";
import EmptyState from "shared/empty/empty";
import TablePagination from "shared/tablePagination/tablePagination";
import Table from "vgg-ui-react/dist/components/Core/Table";
import "./settlements.scss";
import { useHistory } from "react-router-dom";

const Settlements = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [, setSearchValue] = useState("");
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { fetchingSettlements, settlementsPayload } = useSelector(
    ({ account }) => {
      return {
        fetchingSettlements: account?.fetchingSettlements,
        settlementsPayload: account?.settlementsPayload,
      };
    }
  );

  useEffect(() => {
    dispatch(fetchSettlements(currentPage));
  }, [dispatch, currentPage]);

  const handleSearch = _debounce((event) => {
    const keyword = event.target.value;
    dispatch(fetchSettlements(1, keyword));
  }, 1000);

  return (
    <div className="settlement-page-container table-wrapper">
      <div className="border-bottom p-3">
        <h4 className="page-title">Settlement</h4>
      </div>

      <div className="border-bottom px-3 pt-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="search-container">
            <input
              type="search"
              placeholder="search"
              className="form-control"
              data-testid="search-input"
              onChange={(e) => {
                setSearchValue(e.target.value);
                handleSearch(e);
              }}
            />
            <i className="ri-search-line"> </i>
          </div>
          <div className="d-flex align-items-center">
            {/* <FilterDropdown
              dropdownOpen={showFilterDropdown}
              disabled={true}
              toggleDropdown={toggleFilterDropdown}
            /> */}
          </div>
        </div>

        <Table cellVariants="default" className="table-striped">
          <thead>
            <tr>
              <th>Batch ID</th>
              <th>Amount</th>
              <th>Settlment Fee</th>
              <th>Date Created</th>
              <th>{""}</th>
            </tr>
          </thead>
          {fetchingSettlements ? (
            <tbody style={{ borderTop: "none" }}>
              <LoadingTableContent colSpan={5} />
            </tbody>
          ) : (
            <>
              {settlementsPayload?.responseData?.items?.length > 0 && (
                <tbody style={{ borderTop: "none" }}>
                  {settlementsPayload?.responseData?.items?.map((account) => {
                    return (
                      <tr key={account.id}>
                        <td>{account.batchId || "-"}</td>
                        <td>
                          <NumericFormat
                            value={account.amount}
                            displayType="text"
                            thousandSeparator
                            prefix={getSymbolFromCurrency(account?.currency)}
                            renderText={(value) => <span>{value || "-"}</span>}
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </td>
                        <td>{account.settlementFee || "-"}</td>
                        <td>
                          {account.createdAt
                            ? format(new Date(account.createdAt), "dd/MM/yyyy")
                            : "-"}
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn action-btn"
                            size="sm"
                            onClick={() =>
                              history.push(
                                `/products/virtual-account/settlement/transactions?batch=${account.batchId}`
                              )
                            }
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </>
          )}
        </Table>
      </div>
      {!fetchingSettlements &&
        !settlementsPayload?.responseData?.items.length && <EmptyState />}
      {settlementsPayload?.responseData?.items?.length > 0 && (
        <div className="px-3">
          <TablePagination
            data={settlementsPayload?.responseData?.items}
            totalCount={settlementsPayload?.responseData?.count}
            currentPage={settlementsPayload?.responseData?.pageNumber}
            pageSize={settlementsPayload?.responseData?.pageSize}
            onPageChange={(page) => {
              setCurrentPage(page);
            }}
            totalPages={settlementsPayload?.responseData?.pages}
          />
        </div>
      )}
    </div>
  );
};

export default Settlements;
