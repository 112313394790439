import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";
import { CancelMark } from "shared/notifications/icons";
import "./viewModal.scss";

const Item = ({ label, value, loading }) => (
  <div className="view-items">
    <div className="view-title">{label}</div>
    <div className="view-value">
      {loading ? (
        <Spinner color="primary" size="sm" />
      ) : value === 0 ? (
        0
      ) : (
        value || "--"
      )}
    </div>
  </div>
);

const ViewModal = ({ open, toggle, data }) => {
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      backdrop="static"
      className="view-transaction-form-container drawer fadeInRight w-480 animated ml-auto"
    >
      <ModalBody className="no-bb">
        <div className="header">
          <div className="cancel" onClick={toggle}>
            <CancelMark />
          </div>
        </div>
        <div className="title">Transaction Details</div>
        <div className="desc">Information about generated transaction.</div>
        <div className="details">
          <Item label="Account Number" value={data?.beneficiaryAccount} />
          <Item label="Bank" value={data?.sourceBankName} />
          <Item label="Transaction Amount" value={data?.totalAmount} />
          <Item label="Reference" value={data?.transactionReference} />
          <Item label="Currency" value={data?.currency} />
          <Item label="Date Created" value={data?.transactionDate} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ViewModal;
