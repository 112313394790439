import React from "react";
import "./backButton.scss";

const BackButton = ({
  onClick,
  label,
  noPadding,
  remixCode = "arrow-drop-left-line",
  iconClassName = "lg",
}) => {
  return (
    <section
      className={`head-section d-flex sp-mb--24 ${noPadding ? "px-0" : ""}`}
    >
      <div
        className="head-section__icon d-flex justify-content-center align-items-center"
        onClick={(e) =>
          onClick ? onClick(e) : window.location.replace("/products")
        }
      >
        <i className={`ri-${remixCode} ${iconClassName}`}></i> {label || "Back"}
      </div>
    </section>
  );
};
export default BackButton;
