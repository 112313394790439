import React, { useEffect } from "react";
import "./service-details.scss";
import URLS from "helpers/urls";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import TabContents from "./TabContents";
import SettledTransactions from "pages/Settlements/SettledTransactions";

const ServiceDetails = () => {
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === "/account-service") {
      history.push(URLS.Overview);
    } else {
      const path = history.location.pathname.split("/");
      const index = path.indexOf("products");
      const urlPath = path.splice(index, path.length);
      history.push(`/${urlPath.join("/")}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="service-details">
      <Switch>
        <Route
          path="/products/virtual-account/service"
          component={TabContents}
        />
        <Route
          exact
          path={URLS.SettlementTransactions}
          component={SettledTransactions}
        />
      </Switch>
    </div>
  );
};

export default ServiceDetails;
