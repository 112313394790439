import React, { useEffect, useState } from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { SpinLoader } from "components/loader/Loader";

import { Button, Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  accountTypes,
  getAccounts,
  getProviders,
  getStaticAccounts,
  newAccount,
  providerCountry,
 } from "redux/actions/account.action";
import { errorMessage } from "redux/actions/notification";
import Notification from "shared/notifications/notifications";
import "./AccountFormModal.scss";
import SuccessMessage from "./successMessage"

const AccountFormModal = ({ show, toggleModal }) => {
  const [submitStatus, setSubmitStatus] = useState(null);
  const [form, setForm] = useState([]);
  const [validForm, setValidForm] = useState(false);
  const dispatch = useDispatch();
  const [payload, setPayload] = useState({});
  const [step, setStep] = useState(1);
  const [accountType, setAccountType] = useState([]);
  
 
  const {
    newAccountLoading,
    newAccountSuccess,
    providerCountryLoading,
    providerCountrySuccess,
    providerLoading,
    providerSuccess,
    accountTypesLoading,
    accountTypesSuccess,
  } = useSelector(({ account }) => {
    return {
      newAccountLoading: account.newAccountLoading,
      newAccountError: account.newAccountError,
      newAccountSuccess: account.newAccountSuccess,
      accountTypesLoading: account.accountTypesLoading,
      accountTypesError: account.accountTypesError,
      accountTypesSuccess: account.accountTypesSuccess,
      providerLoading: account.providerLoading,
      providerError: account.providerError,
      providerSuccess: account.providerSuccess,
      providerCountryLoading: account.providerCountryLoading,
      providerCountryError: account.providerCountryError,
      providerCountrySuccess: account.providerCountrySuccess,
    };
  });

  
  useEffect(() => {
    // dispatch(accountTypes());
    dispatch(providerCountry());
    dispatch(getStaticAccounts(1, 200, false));
  }, [dispatch]);

  useEffect(() => {
    if (accountTypesSuccess && accountTypesSuccess?.requestSuccessful) {
      let types = []
       for(let type in accountTypesSuccess?.responseData){
         types.push({value: accountTypesSuccess?.responseData[type], label: type})
       }
       setAccountType(types)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountTypesLoading]);

  useEffect(() => {
    if (!newAccountLoading && submitStatus) {
      if (newAccountSuccess?.requestSuccessful) {
        // toggleModal();
        dispatch(getAccounts(1, 10, ""));
        // dispatch(
        //   successMessage(newAccountSuccess?.message || "Virtual Account creation was successful")
        // );
      } else {
        // setFeedBack({error: true, message: newAccountSuccess?.message||"Error occurred while performing this action"})
        dispatch(
          errorMessage(newAccountSuccess?.message || "Error occurred while performing this action")
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAccountSuccess]);

  const step2Value = ["firstName", "lastName", "phoneNumber", "dateOfBirth", "kycType", "kycValue"]

  const checkForm = () => {
    const obj = form?.state?.invalidInputs;
    const emptyValidation =
      obj && Object?.keys(obj).length === 0 && obj?.constructor === Object;
    if(step === 2) {
      for(let stepVal in obj) {
        if(step2Value.indexOf(stepVal) > -1) {
           setValidForm(false);
           return
        }
      }
      setValidForm(true);
      return 
    }
    setValidForm(emptyValidation);
  };

  const closeModal = () => {
    setSubmitStatus(false);
    toggleModal();
    setStep(1)
    setPayload({});
  }

  useEffect(() => {
    checkForm()
  }, [payload]) 
  
  const submit = () => {
    if(new Date(payload?.dateOfBirth) > new Date()) {
      dispatch(errorMessage("Valid date of birth is required"))
      return;
    }
    setSubmitStatus(true);
    const data =       {
      "providerCode": payload?.providerCode,
      "accountType": payload?.accountType,
      "kyc": {
        "firstName": payload?.firstName,
        "lastName": payload?.lastName,
        "dateOfBirth": payload?.dateOfBirth,
        "phoneNumber": payload?.phoneNumber,
        "kycType": "BVN",
        "kycValue": payload?.kycValue
      },
      "accountName": payload?.accountName
    };
    dispatch(newAccount(data));
  };

  console.log({providerCountrySuccess})
 
  return (
    <div>
      <Modal
        centered={true}
        toggle={closeModal}
        isOpen={show}
        backdrop="static"
        className="account-modal-container"
      >
        {!newAccountLoading && <ModalHeader className="border-bottom-0" toggle={toggleModal}>
           Create account{!newAccountSuccess?.requestSuccessful && <>({step}/2)</>}
           <div className="desc">Generate a new virtual account for users.</div>
        </ModalHeader>}
      <Notification />
        <ModalBody>
          {newAccountLoading?<>
             <div className="loading-content">
                 <Spinner size="lg" color="primary"/>
                 <div className="submitting">Submitting Information</div>
                 <div className="please-wait">Please wait ...</div>
             </div>
          </>:<>
          {submitStatus && newAccountSuccess?.requestSuccessful?<SuccessMessage closeModal={closeModal}/>: <><AvForm
            ref={(c) => setForm(c)}
            onKeyUp={checkForm}
          >
            {step === 1?<>
            <div className="md-3">
              <label className="form-label" htmlFor="country">
              Country
              </label>
              <AvField
                type="select"
                name="country"
                value={payload?.country}
                onChange={(e) => {
                  setPayload({...payload, country: e.target.value});
                  dispatch(getProviders(e.target.value))
                }}
                validate={{
                  required: {
                    value: step ===1? true: false,
                    errorMessage: "Country is required",
                  },
                }}
              >
                <option disabled selected value="">
                 please select a Country
                  {/* {providerCountryLoading ? "Loading..." : " please select a Country"} */}
                </option>
                <option value="NGA">Nigeria</option>
                {/* {providerCountrySuccess?.responseData?.items?.map((country) => (
                  <option
                    className="text-capitalize"
                    key={country.isO3}
                    value={country.isO3}
                  >
                    {country.name}
                  </option>
                ))} */}
              </AvField>
            </div>
            
            {(providerLoading || providerSuccess?.responseData?.items) && <div className="md-3">
              <label className="form-label" htmlFor="provider">
                Choose Provider
              </label>
              <AvField
                type="select"
                name="provider"
                value={payload?.providerCode}
                onChange={(e) => setPayload({...payload, providerCode: e.target.value})}
                validate={{
                  required: {
                    value: step ===1? true: false,
                    errorMessage: "Provider is required",
                  },
                }}
              >
                <option disabled selected value="">
                  {providerLoading ? "Loading..." : " please select provider"}
                </option>
                { providerSuccess?.responseData?.items?.map((provider) => (
                  <option
                    className="text-capitalize"
                    key={provider.id}
                    value={provider.code}
                  >
                    {provider.name}
                  </option>
                ))}
              </AvField>
            </div>}
            <div className="mb-3">
              <label className="form-label" htmlFor="accountName">
                Account Name
              </label>
              <AvField
                autoComplete="off"
                type="text"
                onChange={(e) => setPayload({...payload, accountName: e.target.value})}
                name="accountName"
                placeholder="Account Name"
                value={payload?.accountName}
                validate={{
                  required: {
                    value: step ===1? true: false,
                    errorMessage: "Account name is required",
                  },
                }}
              />
            </div>
            <div className="md-3">
              <label className="form-label" htmlFor="accountType">
                 Account Type
              </label>
              <AvField
                type="select"
                value={payload?.accountType}
                name="accountType"
                onChange={(e) => setPayload({...payload, accountType: e.target.value})}
                validate={{
                  required: {
                    value: step ===1? true: false,
                    errorMessage: "Account Type is required",
                  },
                }}
              >
                <option disabled selected value="">
                  {accountTypesLoading ? "Loading..." : " please select Account Type"}
                </option>
                {[{value:"STATIC", label: "Static"},{value:"STATIC_COLLECTION", label: "Static Collection"}]?.map((type) => (
                  <option
                    className="text-capitalize"
                    key={type?.value}
                    value={type?.value}
                  >
                    {type?.label}
                  </option>
                ))}
              </AvField>
            </div>
           </>:<>
           <div className="mb-3">
              <label className="form-label" htmlFor="kycValue">
                BVN
              </label>
              <AvField
                autoComplete="off"
                type="number"
                name="kycValue"
                value={payload?.kycValue||""}
                placeholder="e.g 0123456789"
                onChange={(e) => setPayload({...payload, kycValue: e.target.value})}

                validate={{
                  required: {
                    value: true,
                    errorMessage: "Your BVN is required",
                  },
                  maxLength: {
                    value: 11,
                    errorMessage:
                      "Your bvn must be 11 characters",
                  },
                  minLength: {
                    value: 11,
                    errorMessage:"Your bvn must be 11 characters"
                  }
                }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="firstName">First Name</label>
              <AvField
                type="text"
                name="firstName"
                onChange={(e) => setPayload({...payload, firstName: e.target.value})}
                className="form-field"
                value={payload?.firstName||""}
                placeholder="First Name"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Please enter First Name",
                  },
                }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="lastName">Last Name</label>
              <AvField
                type="text"
                name="lastName"
                onChange={(e) => setPayload({...payload, lastName: e.target.value})}
                className="form-field"
                value={payload?.lastName||""}
                placeholder="Last Name"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Please enter Last Name",
                  },
                }}
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="dateOfBirth">
              D.O.B
              </label>
              <AvField
                type="date"
                name="dateOfBirth"
                placeholder="DD/MM/YY"
                value={payload?.dateOfBirth || ""}
                onChange={(e) => setPayload({...payload, dateOfBirth: e.target.value})}

                validate={{
                  required: {
                    value: true,
                    errorMessage: "Your DOB is required",
                  },
                }}
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="phoneNumber">
              Phone Number
              </label>
              <AvField
                autoComplete="off"
                type="text"
                name="phoneNumber"
                placeholder="e.g 0123456789"
                onChange={(e) => setPayload({...payload, phoneNumber: e.target.value})}
                value={payload?.phoneNumber||""}

                validate={{
                  number: true,
                  required: {
                    value: true,
                    errorMessage: "Phone Number is required",
                  },
                  minLength: {
                    value: 11,
                    errorMessage:
                      "Phone Number must not be more than 11 characters",
                  }
                }}
              />
            </div>

           </>}
          </AvForm>
             <Button
             color="primary"
             className="submit-btn"
             disabled={!validForm || newAccountLoading || (step ===2 && !payload?.kycValue)}
             onClick={()=>step === 1?setStep(2):submit()}
           >
             {step === 1? "Next":"Create account"}
             {newAccountLoading && <SpinLoader size="sm" color="light" />}
           </Button>
           <div>
           {step === 2 && <Button
             color="default"
             className="back-btn"
             onClick={()=>setStep(1)}
           >
             Go back
           </Button>}
           </div>
        
          </>
           }
          </>}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AccountFormModal;
