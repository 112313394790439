import React from "react";

export const WebhookIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame">
      <path id="Vector" d="M35 5C35.9205 5 36.6667 5.7462 36.6667 6.66667V33.3443C36.6667 34.2587 35.9078 35 35.0137 35H4.98633C4.07342 35 3.33333 34.2585 3.33333 33.3443V31.6667H33.3333V12.1667L20 24.1667L3.33333 9.16667V6.66667C3.33333 5.7462 4.07953 5 5 5H35ZM13.3333 25V28.3333H0V25H13.3333ZM8.33333 16.6667V20H0V16.6667H8.33333ZM32.6098 8.33333H7.39023L20 19.6822L32.6098 8.33333Z" fill="#175FFF"/>
      </g>
  </svg>
)

export const CancelMark = () =>(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Frame" clip-path="url(#clip0_4303_9289)">
<path id="Vector" d="M12.0002 10.5862L16.9502 5.63623L18.3642 7.05023L13.4142 12.0002L18.3642 16.9502L16.9502 18.3642L12.0002 13.4142L7.05023 18.3642L5.63623 16.9502L10.5862 12.0002L5.63623 7.05023L7.05023 5.63623L12.0002 10.5862Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_4303_9289">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
)

export const CollopseArrow = () =>(<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
<path d="M7.00023 5.17217L11.9502 0.222168L13.3642 1.63617L7.00023 8.00017L0.63623 1.63617L2.05023 0.222168L7.00023 5.17217Z" fill="#24292E"/>
</svg>
)
export const SuccessMark = () =>(<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="100" height="100" rx="50" fill="#1E944D"/>
<g clip-path="url(#clip0_4303_9273)">
<path d="M44.9998 57.9298L67.9798 34.9473L71.5173 38.4823L44.9998 64.9998L29.0898 49.0898L32.6248 45.5548L44.9998 57.9298Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_4303_9273">
<rect width="60" height="60" fill="white" transform="translate(20 20)"/>
</clipPath>
</defs>
</svg>
)

export const MoreItemIcon = () => <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM16 0C14.9 0 14 0.9 14 2C14 3.1 14.9 4 16 4C17.1 4 18 3.1 18 2C18 0.9 17.1 0 16 0ZM9 0C7.9 0 7 0.9 7 2C7 3.1 7.9 4 9 4C10.1 4 11 3.1 11 2C11 0.9 10.1 0 9 0Z" fill="#0D0F11"/>
</svg>

const Icons = {
  onboarding: (color) => {
    return (
      <span className="icon">
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.99999 4.05757L8.29999 0.757568L9.24266 1.70024L5.94266 5.00023L9.24266 8.30024L8.29999 9.2429L4.99999 5.9429L1.69999 9.2429L0.757324 8.30024L4.05732 5.00023L0.757324 1.70024L1.69999 0.757568L4.99999 4.05757Z"
            fill={color}
          />
        </svg>
      </span>
    );
  },
};

export default Icons