import React from "react";
import "./selectCountry.scss";

const SelectCountry = ({ onSelect, countries, onSearch, searchValue, selectedCountry }) => {
  return (
    <div className="col-12">
      <div className="wrap-search" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
        <input
          className="form-control"
          placeholder="Search countries"
          style={{
            width: "100%",
            padding: "6px 10px",
          }}
          onChange={onSearch}
        />
      </div>
      <div className="countries-main">
        <p className="countries-main__instruction">Choose any country to continue..</p>
        <div className="countries-main__list">
          {countries && countries.length > 0 ? (
            <>
              {countries.map(country => (
                <div
                  title={!country.active ? `No provider available for ${country.name} yet` : ""}
                  className={`d-flex align-items-center justify-content-between country-list ${country.code === selectedCountry ? "active-country" : ""} ${country.active ? "enabled" : "disabled"}`}
                  onClick={() => country.active && onSelect(country.code)}
                >
                  <span>{country.name}</span>
                  <i className="ri-arrow-right-s-line"></i>
                </div>
              ))}
            </>
          ) : (
            <div className="d-flex align-items-center justify-content-center flex-wrap" style={{ height: "180px" }}>
              {searchValue ? <span>"{`${searchValue}`}" not found</span> : <span>No Data</span>}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectCountry;
