import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { LoadingTableContent } from "components/loader/Loader";
import EmptyState from "shared/empty/empty";
import "./bottomCards.scss";
import Table from "vgg-ui-react/dist/components/Core/Table";

const dataMapping = {
  totalCountries: "Countries",
  totalCurrencies: "Currencies",
  totalDynamicAccounts: "Dynamic Accounts",
  totalProviders: "Providers",
  totalProvidersOnboarded: "Onboarded Providers",
  totalStaticAccounts: "Static Accounts",
  totalVirtualAccounts: "Virtual Accounts",
};

export const options = {
  responsive: true,
  elements: {
    point: {
      radius: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "",
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        display: false,
      },
    },
  },
};

const BottomCards = ({ data, loading, activeProviders }) => {
  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    if (data) {
      setDataSource({
        labels: Object.values(dataMapping),
        datasets: [
          {
            id: 1,
            label: "",
            data: [
              data["totalCountries"],
              data["totalCurrencies"],
              data["totalDynamicAccounts"],
              data["totalProviders"],
              data["totalProvidersOnboarded"],
              data["totalStaticAccounts"],
              data["totalVirtualAccounts"],
            ],
            borderColor: "#175FFF",
            borderWidth: 1,
            backgroundColor: "transparent",
          },
        ],
      });
    }
  }, [data]);

  return (
    <div className="row align-items-stretch">
      <div className="c-dashboardInfo col-md-6">
        <div className="wrap">
          <h4 className="d-flex align-items-center heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
            <span>Active Providers </span>
          </h4>
          <div className="hind-font caption-12 c-dashboardInfo__count mb-3">{activeProviders?.length}</div>
          <div className="hind-font caption-12 c-dashboardInfo__count" style={{ maxHeight: "300px", overflowY: "auto" }}>
            <Table striped borderless size="md" responsive className="table-striped overflow-auto">
              <thead style={{ position: "sticky", top: 0, background: "hsl(221, 90%, 95%)" }}>
                <tr>
                  <th>Provider ID</th>
                  <th>Provider Name</th>
                  <th>Provider Code</th>
                </tr>
              </thead>
              {loading ? (
                <tbody style={{ borderTop: "none" }}>
                  <LoadingTableContent colSpan={2} />
                </tbody>
              ) : (
                <tbody style={{ borderTop: "none" }}>
                  {activeProviders.length ? (
                    <>
                      {activeProviders?.map(val => (
                        <tr key={val?.id}>
                          <td>{val?.id || "--"}</td>
                          <td>{val?.name || "--"}</td>
                          <td>{val?.code || "--"}</td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr colSpan={2}>
                      <td>
                        <EmptyState />
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </Table>
          </div>
          <span className="hind-font caption-12 c-dashboardInfo__subInfo"></span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="row">
          <div className="c-dashboardInfo col-md-6">
            <div className="wrap">
              <h4 className="d-flex align-items-center heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                <span>Dynamic Virtual Accounts Created</span>
              </h4>
              <div className="hind-font caption-12 c-dashboardInfo__count mb-3">
                {/* <Line options={options} data={data} height="80vh" /> */}
                {data?.totalDynamicAccounts || 0}
              </div>
              <div className="hind-font caption-12 c-dashboardInfo__count">
                {/* <Line options={options} data={data} height="80vh" /> */}
                {dataSource && <Line datasetIdKey="id" data={dataSource} options={options} />}
              </div>
            </div>
          </div>

          <div className="c-dashboardInfo col-md-6">
            <div className="wrap">
              <h4 className="d-flex align-items-center heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                <span>Static Virtual Accounts Created</span>
              </h4>
              <div className="hind-font caption-12 c-dashboardInfo__count mb-3">
                {/* <Line options={options} data={data} height="80vh" /> */}
                {data?.totalStaticAccounts || 0}
              </div>
              <div className="hind-font caption-12 c-dashboardInfo__count">
                {/* <Line options={options} data={data} height="80vh" /> */}
                {dataSource && <Line datasetIdKey="id" data={dataSource} options={options} />}
              </div>
              <span className="hind-font caption-12 c-dashboardInfo__subInfo"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomCards;
